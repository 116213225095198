import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import fetchIntercept from 'fetch-intercept';
import { tokenRegister } from "./services/tokenRegister";

import ResetPassword from "./pages/resetPassword";
import ForgotPassword from "./pages/forgotPassword";
import Login from "./pages/login";
import LoggedInAdmin from './pages/loggedInAdmin';
import LoggedInAgentManager from './pages/agent/loggedInAgentManager';
import ProtectedRouteAdmin from './components/protectedRouteAdmin';
import ProtectedRouteAgentManager from './components/protectedRouteAgentManager';
import NoMatch from './pages/noMatch';
import LoginToken from './pages/loginToken';
import SavillsDubaiSquareTemplateOne from './previews/savills-dubai/squareTemplateOne';
import ReportPdfHeaderSlide from './previews/report-pdf/header-slide';
import LoggedInAgentRegular from './pages/agent/loggedInAgentRegular';
import WhatsappMessage from './pages/public/whatsappMessage';
import ProptreeMenu from './proptree/menu';
import PrivacyPolicy from './pages/privacyPolicy';
import LoginTokenReport from './pages/loginTokenReport';
import TwoStepVerification from './pages/twoStepVerification';
import RedirectSite from './pages/public/redirectSite';
import RedirectQuestions from './pages/public/redirectQuestions';
import Form from './pages/public/form';
import DisplayVertical from './pages/public/displayVertical';
import ProptreeHome from './proptree/home';
import Proptree404 from './proptree/404';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {
        this._setInterceptor();
    }

    _setInterceptor() {
        fetchIntercept.register({
            request: function (url, config) {
                return [url, config];
            },
            requestError: function (error) {
                return error;
            },
            response: function (response) {
                if (response.status === 401) {
                    tokenRegister.remove();
                    window.alert("Token has expired");
                    window.location.replace(window.location.protocol + "//" + window.location.host + "/login");
                }
                if (response.headers.get('x-auth-token')) {
                    if (response.headers.get('x-auth-token') === 'remove_token') {
                        tokenRegister.remove();
                        window.alert("Token has expired");
                        window.location.replace(window.location.protocol + "//" + window.location.host + "/login");
                    } else {
                        tokenRegister.set(response.headers.get('x-auth-token'));
                    }
                }
                return response;
            },
            responseError: function (error) {
                return error;
            }
        });
    }

    render() {
        return (
            <div className={(window.location.pathname.indexOf('/form/') !== -1 || window.location.origin.indexOf('form.') !== -1) ? "" : "bg-gray-50"}>
                <BrowserRouter>
                    <Switch>

                        {/*PUBLIC*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/" component={Login} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/verification" component={TwoStepVerification} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/login" component={Login} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/login/:user" component={LoginToken} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/whatsapp-message/:lead" component={WhatsappMessage} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/next/site/:lead" component={RedirectSite} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/next/form/:lead" component={RedirectQuestions} />
                        }

                        {/*ADMIN*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <ProtectedRouteAdmin path='/dashboard' component={LoggedInAdmin} />
                        }

                        {/*AGENT MANAGER*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <ProtectedRouteAgentManager path='/manager' component={LoggedInAgentManager} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <ProtectedRouteAgentManager path='/agent' component={LoggedInAgentRegular} />
                        }

                        {/*PREVIEWS*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/report/header/one" component={ReportPdfHeaderSlide} />
                        }

                        {/*PROPTREE MAIN PAGE (INTERNAL)*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route path="/home/proptree" component={ProptreeHome} />
                        }
                        {/*PROPTREE 404 (INTERNAL)*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/404/proptree" component={Proptree404} />
                        }
                        {/*PROPTREE PAGES (INTERNAL)*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route path="/:id/proptree" component={ProptreeMenu} />
                        }

                        {/*PROPTREE MAIN PAGE (LIVE)*/}
                        {
                            (window.location.origin.indexOf('proptr.') !== -1) &&
                            <Route exact path="/" component={ProptreeHome} />
                        }
                        {/*PROPTREE 404 (LIVE)*/}
                        {
                            (window.location.origin.indexOf('proptr.') !== -1) &&
                            <Route exact path="/404" component={Proptree404} />
                        }
                        {/*PROPTREE PAGES (LIVE)*/}
                        {
                            (window.location.origin.indexOf('proptr.') !== -1) &&
                            <Route path="/:id" component={ProptreeMenu} />
                        }

                        {/*FORM*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('form.') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/form/:form" component={Form} />
                        }
                        {
                            (window.location.origin.indexOf('form.') !== -1) &&
                            <Route exact path="/:form" component={Form} />
                        }
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('form.') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route exact path="/form/:form/:lead" component={Form} />
                        }
                        {
                            (window.location.origin.indexOf('form.') !== -1) &&
                            <Route exact path="/:form/:lead" component={Form} />
                        }

                        {/*PRIVACY POLICY*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route path="/privacy-policy" component={PrivacyPolicy} />
                        }

                        {/*DISPLAY*/}
                        {
                            (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('app.') !== -1) &&
                            <Route path="/display-grid" component={DisplayVertical} />
                        }

                        <Route component={NoMatch} />

                    </Switch>
                </BrowserRouter>
            </div>
        )
    }

}

export default Main