import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CheckFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            sort: 'lastUpdated',
            order: 'ascending',
            page: 1,
            page_count: 100,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    async componentWillReceiveProps(nextProps) {
        if (!this.state.feed.id && nextProps.feed) {
            this.functions.getFeed(nextProps.feed.id);
        }
        await this.promisedSetState({
            open: nextProps.open,
            feed: nextProps.feed ? nextProps.feed : {}
        })
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
        },
        getFeed: async (id) => {
            try {
                await this.promisedSetState({ loading_forms: true });
                let response = await this.calls.getFeed(id);
                await this.promisedSetState({
                    feed: { ...this.state.feed, ...response.data },
                    loading_forms: false
                });
                this.functions.sideScroll('table');
                this.functions.sideScroll('table2');
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_forms: false
                })
            }
        },
        sideScroll: (id) => {
            try {
                const slider = document.querySelector('#' + id);
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1; //scroll-fast
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        },
        checkImageLink: (item) => {
            if (this.state.feed.channel === "facebook") {
                return item["image[0].url"];
            } else if (this.state.feed.channel === 'google') {
                return item["Image URL"];
            } else if (this.state.feed.channel === 'tiktok') {
                return item["image_link"];
            } else if (this.state.feed.channel === 'snapchat') {
                return item["image_link"];
            } else {
                return false;
            }
        }
    };

    renders = {

    };

    calls = {
        getFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light/" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.feed.name}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex flex-row">
                                                <div className="grid grid-cols-12 w-full pt-6">

                                                    {
                                                        Array.isArray(this.state.feed.listings) && this.state.feed.listings.length > 0 &&
                                                        <div className={"col-span-12"}>
                                                            <div className="px-6">
                                                                <div className={"overflow-x-auto"}>
                                                                    <div id={"table2"} className={"overflow-x-auto flex flex-row"}>
                                                                        {
                                                                            this.state.feed.listings.filter((item, index) => {
                                                                                return index >= (this.state.page * this.state.page_count - this.state.page_count) && index < (this.state.page * this.state.page_count)
                                                                            }).map((item, index) => {
                                                                                return (
                                                                                    <div style={{ width: '250px', height: "250px" }} className="mr-6 bg-white relative">
                                                                                        <iframe src={item.url + "&legacy=false"} width="250" height="250" frameborder="0" scrolling="no" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className={"col-span-12 mt-5"}>
                                                        {
                                                            Array.isArray(this.state.feed.data) && this.state.feed.data.length > 0 &&
                                                            <div className="overflow-hidden">
                                                                <div className="flex flex-col">
                                                                    <div className="w-full">
                                                                        <div id={"table"} className="overflow-scroll">
                                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                                <thead className="bg-gray-100">
                                                                                    <tr>
                                                                                        {
                                                                                            Object.keys(this.state.feed.data[0]).map((key) => {
                                                                                                return (
                                                                                                    <th
                                                                                                        style={{ whiteSpace: "nowrap" }}
                                                                                                        scope="col"
                                                                                                        className="px-6 py-3 border-r text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                                                    >
                                                                                                        {key}
                                                                                                    </th>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className={"bg-white divide-y divide-gray-200"}>
                                                                                    {this.state.feed.data.filter((item, index) => {
                                                                                        return index >= (this.state.page * this.state.page_count - this.state.page_count) && index < (this.state.page * this.state.page_count)
                                                                                    }).map((item, index) => (
                                                                                        <tr onClick={() => {
                                                                                            this.setState({
                                                                                                feed_item: index == this.state.feed_item ? null : index
                                                                                            })
                                                                                        }} className={(this.state.feed_item == index ? "bg-gray-100" : "") + " cursor-pointer"} key={index}>
                                                                                            {
                                                                                                Object.keys(item).map((key, index) => {
                                                                                                    return (
                                                                                                        <td style={{ whiteSpace: "nowrap" }} className={"px-6 py-4 border-r whitespace-nowrap text-sm font-medium text-gray-900"}>{item[key]}</td>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-6 py-4 flex justify-end">

                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CheckFeed;
