import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            campaigns: [],
            campaign: {},
            error: ""
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            campaigns: this.props.campaigns
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.setState({
                campaigns: nextProps.campaigns,
                campaign: {
                    id: "",
                    channel: { id: 1, name: "Facebook" },
                },
                open: false,
            });
        }
        this.setState({
            open: nextProps.open
        })
    }

    functions = {
        createCampaign: async () => {
            try {
                this.state.campaigns.push(this.state.campaign);
                let response = await this.calls.createCampaign({
                    campaigns: this.state.campaigns
                });
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggle();
                    if (this.props.onCampaign) {
                        this.props.onCampaign(response.data.campaigns);
                    }
                })
            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false
                })
            }
        }
    }

    calls = {
        createCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/enterprise/edit/" + this.props.enterpriseId;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">Add Campaign</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-indigo-300">
                                                        Get started by filling in the information below to create your new project.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pt-6">
                                                        <div>
                                                            <label htmlFor="campaign-id" className="block text-sm font-medium text-gray-900">
                                                                Id
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.campaign.id = event.target.value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        })
                                                                    }}
                                                                    value={this.state.campaign.id}
                                                                    type="text"
                                                                    name="campaign-id"
                                                                    id="campaign-id"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="campaign-name" className="block text-sm font-medium text-gray-900">
                                                                Name
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.campaign.name = event.target.value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        })
                                                                    }}
                                                                    value={this.state.campaign.name}
                                                                    type="text"
                                                                    name="campaign-name"
                                                                    id="campaign-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="campaign-channel" className="block text-sm font-medium text-gray-900">
                                                                Channel
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.campaign.channel = option;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        })
                                                                    }}
                                                                    selected={this.state.campaign.channel}
                                                                    options={[
                                                                        { id: 1, name: 'Facebook' },
                                                                        { id: 2, name: 'Google' },
                                                                    ]}
                                                                    name="campaign-channel"
                                                                    id="campaign-channel"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            {
                                                !this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => {
                                                        this.props.toggle();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading) {
                                                        this.setState({
                                                            loading: true
                                                        }, () => {
                                                            this.functions.createCampaign();
                                                        })
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateCampaign;
