import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            user: {},
            message: "",
            access: {}
        }
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.state.user = (nextProps.user ? nextProps.user : {
                status: { id: 1, name: 'Active' },
                whatsapp: { id: 1, name: 'True' },
                access: { id: 2, name: 'Agent' }
            });
        }
        this.setState({
            user: this.state.user,
            open: nextProps.open
        })
    }

    functions = {
        createUser: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let user = JSON.parse(JSON.stringify(this.state.user));
                if (this.state.password !== "") {
                    user.password = this.state.password;
                }
                let response = await this.calls.createUser(user);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUser) {
                    this.props.onUser(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateUser: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let user = JSON.parse(JSON.stringify(this.state.user));
                if (this.state.password !== "") {
                    user.password = this.state.password;
                }
                let response = {};
                if (this.props.view == "agent_list") {
                    response = await this.calls.updateUserFromAgentView(user);
                } else {
                    response = await this.calls.updateUser(user);
                }
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUpdate) {
                    this.props.onUpdate(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.user.image = response.data;
                        this.setState({
                            user: this.state.user,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        }
    };

    calls = {
        createUser: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/user/create";
            return apiRegister.call(options, url);
        },
        updateUser: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/user/update/" + this.state.user.id;
            return apiRegister.call(options, url);
        },
        updateUserFromAgentView: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/user/updateUserFromAgentLogin?id=" + this.state.user.id;
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.user.id ? "Update User" : "New User"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 sm:px-6">
                                                    <div className="pt-6 ">
                                                        <div className="mt-1 flex items-center">
                                                            {
                                                                !this.state.user.image &&
                                                                <span className="inline-block h-20 w-20 rounded-full relative overflow-hidden bg-gray-100">
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                    {
                                                                        this.state.loading_image &&
                                                                        <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                this.state.user.image &&
                                                                <div className="rounded-full relative mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                                                    <div>
                                                                        <img
                                                                            className="w-full"
                                                                            src={this.state.user.image}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    {
                                                                        this.state.loading_image &&
                                                                        <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="ml-4 flex">
                                                                <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                    <label
                                                                        htmlFor="user-photo"
                                                                        className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                                    >
                                                                        {
                                                                            this.state.user.image &&
                                                                            <span>Change image</span>
                                                                        }
                                                                        {
                                                                            !this.state.user.image &&
                                                                            <span>Upload image</span>
                                                                        }
                                                                        <span className="sr-only"> user photo</span>
                                                                    </label>
                                                                    {
                                                                        <input
                                                                            ref="uploadImageFile"
                                                                            onClick={() => this.refs.uploadImageFile.value = null}
                                                                            onChange={(e) => this.functions.uploadImage(e)}
                                                                            type="file"
                                                                            accept={"image/png,image/jpg,image/jpeg"}
                                                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200  pt-6 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="agent-access" className="block text-sm font-medium text-gray-900">
                                                                Access
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={true}
                                                                    select={(option) => {
                                                                        this.state.user.access = option;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    selected={this.state.user.access ? this.state.user.access : { id: 2, name: 'Agent' }}
                                                                    options={[
                                                                        { id: 2, name: 'Agent' },
                                                                        //{ id: 3, name: 'Sales manager' },
                                                                    ]}
                                                                    name="agent-access"
                                                                    id="agent-access"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 grid grid-cols-12 sm:px-6 gap-6">
                                                    <div className="space-y-6 pb-5 col-span-6">
                                                        <div>
                                                            <label htmlFor="agent-name" className="block text-sm font-medium text-gray-900">
                                                                Name
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.name = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.name ? this.state.user.name : ""}
                                                                    type="text"
                                                                    name="agent-name"
                                                                    id="agent-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-6 pb-5 col-span-6">
                                                        <div>
                                                            <label htmlFor="agent-status" className="block text-sm font-medium text-gray-900">
                                                                Status
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.user.status = option;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    selected={this.state.user.status ? this.state.user.status : { id: 1, name: 'Active' }}
                                                                    options={[
                                                                        { id: 1, name: 'Active' },
                                                                        { id: 2, name: 'Disabled' },
                                                                    ]}
                                                                    name="agent-status"
                                                                    id="agent-status"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 sm:px-6 grid grid-cols-12 gap-6">
                                                    <div className="space-y-6 pb-5 col-span-6">
                                                        <div>
                                                            <label htmlFor="agent-phone" className="block text-sm font-medium text-gray-900">
                                                                Phone
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.phone = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.phone ? this.state.user.phone : ""}
                                                                    type="phone"
                                                                    name="agent-phone"
                                                                    id="agent-phone"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-6 pb-5 col-span-6">
                                                        <div>
                                                            <label htmlFor="agent-whatsapp" className="block text-sm font-medium text-gray-900">
                                                                WhatsApp
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.user.whatsapp = option;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    selected={this.state.user.whatsapp ? this.state.user.whatsapp : { id: 1, name: 'True' }}
                                                                    options={[
                                                                        { id: 1, name: 'True' },
                                                                        { id: 2, name: 'False' },
                                                                    ]}
                                                                    name="agent-whatsapp"
                                                                    id="agent-whatsapp"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="agent-email" className="block text-sm font-medium text-gray-900">
                                                                Email
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.email = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.email ? this.state.user.email : ""}
                                                                    type="email"
                                                                    name="agent-email"
                                                                    id="agent-email"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="agent-license" className="block text-sm font-medium text-gray-900">
                                                                Permit
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.license = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.license ? this.state.user.license : ""}
                                                                    type="text"
                                                                    name="agent-license"
                                                                    id="agent-license"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.state.user.id) {
                                                        this.functions.updateUser();
                                                    } else {
                                                        this.functions.createUser();
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.user.id &&
                                                    <span>Create</span>
                                                }
                                                {
                                                    this.state.user.id &&
                                                    <span>Update</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateAgent;
