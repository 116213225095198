import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { ChartPieIcon, ChevronDownIcon, ExternalLinkIcon, MapIcon, MinusCircleIcon, PencilAltIcon, PhotographIcon, PlayIcon, PlusCircleIcon, RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateNewProptree from '../../components/createNewProptree';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListProptrees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            meta: {},
            open: false,
            tab: { id: 'agents', name: "Agents", value: 'agent', count: 0 },
            status: { id: 'enabled', name: "Enabled", value: 'enabled' },
            feeds: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            loading_feeds: false
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.listProptrees(true);
    }

    componentWillUnmount() {

    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                });
            }
        },
        listProptrees: async (init) => {
            try {
                await this.promisedSetState({
                    loading: init,
                    loading_feeds: !init
                });
                let response = await this.calls.listProptrees();
                await this.promisedSetState({
                    total: response.meta.total,
                    feeds: response.data,
                    meta: response.meta,
                    loading: false,
                    loading_feeds: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        removeFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeFeed(selected_feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                });
                this.functions.listProptrees();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        disableFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.disableFeed(selected_feed.id);
                await this.promisedSetState({
                    remove: null,
                    remove_name: "",
                });
                this.functions.listProptrees();
            } catch (error) {
                console.log(error);
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        listProptrees: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/listProptrees?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.status.value;
            return apiRegister.call(options, url);
        },
        removeFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/feed-light/" + id;
            return apiRegister.call(options, url);
        },
        disableFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/feed-light/disable/" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className={"sm:p-0 p-6 pt-0 grid grid-cols-12 h-full min-h-full bg-gray-50"}>

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading proptree ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={this.state.remove && this.state.remove.disabled ? "Delete Feed" : "Disable feed"}
                    description={"Are you sure you want to " + (this.state.remove && this.state.remove.disabled ? "delete" : "disable") + " this feed? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={this.state.remove && this.state.remove.disabled ? "delete" : "disable"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name"'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            if (this.state.remove.disabled) {
                                this.functions.removeFeed();
                            } else {
                                this.functions.disableFeed();
                            }
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*ERROR MODAL*/}
                <WarningModal
                    open={this.state.sync_error ? true : false}
                    softWarning={true}
                    title={"Sync overload"}
                    description={"Please wait 10 min"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            sync_error: null
                        })
                    }}
                />

                {/*CREATE FEED*/}
                <CreateNewProptree
                    enterprise={this.state.user.enterprise}
                    feed={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onCreate={() => {
                        this.setState({
                            open: !this.state.open
                        })
                        this.functions.listProptrees();
                    }}
                    onUpdate={() => {
                        this.functions.listProptrees();
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.tab}
                                options={[
                                    { id: 'agents', name: "Agents", value: 'agent', count: this.state.meta.agents },
                                    { id: 'custom', name: "Custom", value: 'custom', count: this.state.meta.custom },
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.listProptrees();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*STATUS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.status}
                                options={[
                                    { id: 'enabled', name: "Enabled", value: 'enabled', count: this.state.meta.enabled },
                                    { id: 'disabled', name: "Disabled", value: 'disabled', count: this.state.meta.disabled }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        status: option,
                                        page: 1
                                    });
                                    this.functions.listProptrees();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-4"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.listProptrees();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.listProptrees();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*PROPTREE*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className={(this.state.loading_sync ? "overflow-hidden" : "") + " bg-white shadow-lg border rounded-lg"}>
                                    <div className="flex p-4 lg:px-6 border-b border-gray-100 items-center">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        {
                                            this.state.status.value == "enabled" &&
                                            this.state.tab.value == "custom" &&
                                            this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value <= this.state.total &&
                                            !this.state.loading_sync &&
                                            <div className=" rounded-md text-red-600 font-medium text-sm px-4 py-2">
                                                Upgrade or disable custom proptrees {this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value ? "[" + this.state.user.enterprise.limitProptreeAmount.value + "/" + this.state.total + " created]" : "[-/- created]"}
                                            </div>
                                        }
                                        {
                                            //this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value > this.state.total &&
                                            //this.state.user.enterprise && this.state.user.enterprise.managerUIcreateFeeds && this.state.user.enterprise.managerUIcreateFeeds.value === 'enabled' &&
                                            this.state.user.access && this.state.user.access.id === 1 &&
                                            <div className="relative">
                                                {
                                                    !(this.state.status.value == "enabled" && this.state.tab.value == "custom") &&
                                                    <div className="left-0 right-0 absolute cursor-not-allowed bottom-0 top-0 bg-white opacity-50"></div>
                                                }
                                                <div onClick={() => {
                                                    this.setState({
                                                        open: true,
                                                        selected: null
                                                    })
                                                }} className="text-sm font-medium cursor-pointer h-10  px-4  ml-4 rounded-md bg-purple-100 border-purple-500 items-center flex flex-row text-purple-500 hover:text-purple-700">
                                                    <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                    Create {this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value ? "[" + (this.state.user.enterprise.limitProptreeAmount.value - this.state.total) + " left]" : "[- left]"}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.feeds.length < 1 &&
                                        <div className="h-110 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_feeds &&
                                                <div className="font-medium text-sm">No Proptree available</div>
                                            }
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div id={"table_container"} className={"relative min-h-110"}>
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listings</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Channel</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Theme</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Type</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Language</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Currency</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Property</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.feeds.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        <td className="text-left pl-6">
                                                                            <div className="flex flex-row">
                                                                                <Menu as="div" className="relative inline-block text-left">
                                                                                    <div>
                                                                                        <Menu.Button className="inline-flex relative items-center hover:bg-gray-50 justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                            {
                                                                                                !agent.loading &&
                                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            }
                                                                                            {
                                                                                                agent.loading &&
                                                                                                <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                        </Menu.Button>
                                                                                    </div>
                                                                                    <Transition
                                                                                        as={Fragment}
                                                                                        enter="transition ease-out duration-100"
                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                        leave="transition ease-in duration-75"
                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                    >
                                                                                        <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg" style={{ zIndex: "9999" }}>
                                                                                            <div className="py-1">
                                                                                                {
                                                                                                    [
                                                                                                        { name: "Edit", value: "edit", icon: PencilAltIcon },
                                                                                                        { name: "Open", value: "open", icon: ExternalLinkIcon },
                                                                                                        { name: "Disable", value: "disable", icon: TrashIcon },
                                                                                                        { name: "Remove", value: "remove", icon: TrashIcon }
                                                                                                    ].filter((item) => {
                                                                                                        if (item.value == "edit") {
                                                                                                            return this.state.status.value == "enabled"
                                                                                                        } else if (item.value == "remove") {
                                                                                                            return agent.status == "disabled";
                                                                                                        } else if (item.value == "disable") {
                                                                                                            return agent.status == "enabled";
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                    }).map((item) => {
                                                                                                        return (
                                                                                                            <Menu.Item>
                                                                                                                {({ active }) => (
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            if (item.value == "edit") {
                                                                                                                                this.setState({
                                                                                                                                    open: true,
                                                                                                                                    selected: agent
                                                                                                                                })
                                                                                                                            } else if (item.value == "disable" || item.value == "remove") {
                                                                                                                                this.setState({
                                                                                                                                    remove: agent
                                                                                                                                })
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        className={classNames(
                                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                            'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                            (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <div className="flex flex-1">{item.name}</div>
                                                                                                                        <item.icon className="w-5" />
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Menu.Item>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </Menu.Items>
                                                                                    </Transition>
                                                                                </Menu>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-left">
                                                                            <div className="flex flex-row">
                                                                                {
                                                                                    !agent.profileImage &&
                                                                                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    agent.profileImage &&
                                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                                        <img className="h-10 w-10 rounded-full" src={agent.profileImage} alt="" />
                                                                                    </div>
                                                                                }
                                                                                <div className="ml-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.profileFirstName}</div>
                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap">{agent.profileSurName}</div>
                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap">Name</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.alias ? agent.alias : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Custom alias</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.shortId ? agent.shortId : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Platform alias</div>
                                                                        </td>
                                                                        {
                                                                            false &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.profileCompany ? agent.profileCompany : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Company Name</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.whatsAppCountryCode ? agent.whatsAppCountryCode.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Country code</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.whatsAppNumber ? agent.whatsAppNumber : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Whatsapp</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.form ? agent.form.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Form</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.pageLoad : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Page loads</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.clicks : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Listing clicks</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.unqualifiedLeads : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Unqualified leads</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.preQualifiedLeads : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Pre-qualified leads</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.qualifiedLeads : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Qualified leads</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.stats ? agent.stats.postQualifiedLeads : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Post-qualified leads</div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div className="rounded-b-lg relative">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    this.state.feeds.length > 0 &&
                                                    <div className="hidden sm:block">
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state.total}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.listProptrees();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.listProptrees();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.listProptrees();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div >
        )
    }
}

export default ListProptrees;
