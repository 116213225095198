import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateNewProptree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            adbooster_type: { id: 1, name: "Property Booster" },
            dontShowSubmitButton: false,
            preview: null,
            preview_params_missing: true,
            proptree: {}
        }
    };

    componentDidMount() {

    }

    async componentWillReceiveProps(nextProps) {

        let user = userRegister.get();
        await this.promisedSetState({ user: user });

        if (!this.state.open && nextProps.open) {
            this.functions.getForms();
            this.state.proptree = (nextProps.proptree ? nextProps.proptree : {});
            this.state.preview = null;
            this.state.preview_params_missing = true;
        }

        //let market = this.state.user.enterprise && this.state.user.enterprise.market && this.state.user.enterprise.market.value ? this.state.user.enterprise.market.value : "ae";
        //let currency = this.state.user.enterprise && this.state.user.enterprise.baseCurrency && this.state.user.enterprise.baseCurrency.value ? this.state.user.enterprise.baseCurrency.value.toUpperCase() : "AED";

        this.setState({
            preview_params_missing: this.state.preview_params_missing,
            preview: this.state.preview,
            proptree: this.state.proptree,
            open: nextProps.open,
            user_enterprise: nextProps.enterprise,
            dontShowSubmitButton: nextProps.dontShowSubmitButton
        });

    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
        },
        getForms: async () => {
            try {
                await this.promisedSetState({ loading_forms: true });
                let response = await this.calls.getForms();
                this.setState({
                    forms: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading_forms: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_forms: false
                })
            }
        },
        createProptree: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.createProptree(proptree);
                await this.promisedSetState({ loading: false });
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateFeed: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.updateFeed({
                    campaigns: proptree.campaigns
                });
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.proptree.profileImage = response.data;
                        this.setState({
                            proptree: this.state.proptree,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        }
    };

    renders = {
        valid: () => {
            let valid_answers = [];
            let mandatory = ["profileImage", "profileFirstName", "profileCompany", "whatsAppNumber", "whatsAppCountryCode", "form"];
            mandatory.map((item) => {
                if (item == "whatsAppCountryCode") {
                    if (this.state.proptree.whatsAppCountryCode && this.state.proptree.whatsAppCountryCode.value) {
                        valid_answers.push(item);
                    }
                } else if (item == "form") {
                    if (this.state.proptree.form && this.state.proptree.form.id) {
                        valid_answers.push(item);
                    }
                } else if (typeof this.state.proptree[item] == "string" && this.state.proptree[item] !== "") {
                    valid_answers.push(item);
                }
            });
            console.log(valid_answers);
            return valid_answers.length == mandatory.length;
        }
    };

    calls = {
        getForms: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/form";
            return apiRegister.call(options, url);
        },
        createProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptree";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-7xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-row bg-white shadow-xl">

                                        <div className="md:flex hidden flex-1 flex-col">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                <div className="bg-gray-100 relative border overflow-hidden" style={{ borderRadius: '30px', width: "350px", height: "600px" }}>
                                                    <div className="w-full border-b h-16 bg-white"></div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.proptree.id ? "Update Proptree" : "New Proptree"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 h-0 overflow-y-auto">
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col pt-6">
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-4">
                                                                <div className="mt-1 flex items-center">
                                                                    {
                                                                        !this.state.proptree.profileImage &&
                                                                        <span className="inline-block h-20 w-20 rounded-full relative overflow-hidden bg-gray-100">
                                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                            </svg>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </span>
                                                                    }
                                                                    {
                                                                        this.state.proptree.profileImage &&
                                                                        <div className="rounded-full relative mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                                                            <div>
                                                                                <img
                                                                                    className="w-full"
                                                                                    src={this.state.proptree.profileImage}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="ml-4 flex">
                                                                        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                            <label
                                                                                htmlFor="user-photo"
                                                                                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                                            >
                                                                                {
                                                                                    this.state.proptree.image &&
                                                                                    <span>Change image</span>
                                                                                }
                                                                                {
                                                                                    !this.state.proptree.image &&
                                                                                    <span>Upload image</span>
                                                                                }
                                                                                <span className="sr-only"> user photo</span>
                                                                            </label>
                                                                            {
                                                                                <input
                                                                                    ref="uploadImageFile"
                                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadImage(e)}
                                                                                    type="file"
                                                                                    accept={"image/png,image/jpg,image/jpeg"}
                                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-8 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            Custom alias (optional)
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <div className="bg-gray-50 border border-r-none rounded-l-md shadow-sm items-center inline-flex h-10 px-4">
                                                                                https://proptr.ee/
                                                                            </div>
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.alias = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.alias ? this.state.proptree.alias : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={"appearance-none block rounded-l-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 mt-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            First name
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileFirstName = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileFirstName ? this.state.proptree.profileFirstName : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            Surname
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileSurName = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileSurName ? this.state.proptree.profileSurName : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            Company name
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileCompany = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileCompany ? this.state.proptree.profileCompany : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-2 relative">
                                                                <div className="">
                                                                    <div>
                                                                        <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                            Country code
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.proptree.whatsAppCountryCode = option;
                                                                                    await this.promisedSetState({
                                                                                        proptree: this.state.proptree
                                                                                    });
                                                                                }}
                                                                                disabled={this.state.proptree.id}
                                                                                selected={this.state.proptree.whatsAppCountryCode ? this.state.proptree.whatsAppCountryCode : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: '+971', value: "971" },
                                                                                    //{ id: 1, name: '+46', value: "46" },
                                                                                    //{ id: 2, name: '+45', value: "45" }
                                                                                ]}
                                                                                name="feed-type"
                                                                                id="feed-type"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-4 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            WhatsApp
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.whatsAppNumber = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.whatsAppNumber ? this.state.proptree.whatsAppNumber : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 pb-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="w-full col-span-12 border-b">
                                                                <div className="text-sm font-bold pb-1 text-gray-900">Filter listings</div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-6 relative">
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                            Type
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Sale & Rentals', value: "both" },
                                                                                        { id: 2, name: 'Sale', value: "sale" },
                                                                                        { id: 3, name: 'Rentals', value: "rent" }
                                                                                    ]}
                                                                                    name="feed-type"
                                                                                    id="feed-type"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 2, name: 'Sale', value: "sale" }
                                                                                    ]}
                                                                                    name="feed-type"
                                                                                    id="feed-type"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-6 relative">
                                                                {
                                                                    !(this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-plan" className="block text-sm font-medium text-gray-900">
                                                                            Plan
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                    name="feed-plan"
                                                                                    id="feed-plan"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="px-4 pb-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="w-full col-span-12 border-b">
                                                                <div className="text-sm font-bold pb-1 text-gray-900">Destination</div>
                                                            </div>
                                                        </div>

                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                {
                                                                    !(this.state.proptree.property && this.state.proptree.property.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-form" className="block text-sm font-medium text-gray-900">
                                                                            Form
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.proptree.form = option;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                loader={this.state.loading_forms}
                                                                                disabled={this.state.proptree.id}
                                                                                selected={this.state.proptree.form ? this.state.proptree.form : { id: 0, name: 'Select form', value: "none" }}
                                                                                options={this.state.forms.map((item) => { return { name: item.name, id: item.id } })}
                                                                                name="feed-form"
                                                                                id="feed-form"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className="px-6 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                                {
                                                    !this.state.dontShowSubmitButton &&
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.proptree.id) {
                                                                //this.functions.updateFeed();
                                                            } else {
                                                                if (this.renders.valid()) {
                                                                    this.functions.createProptree();
                                                                }
                                                            }
                                                        }}
                                                        className={(this.renders.valid() ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        {
                                                            this.state.loading &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.proptree.id &&
                                                            <span>Create</span>
                                                        }
                                                        {
                                                            this.state.proptree.id &&
                                                            <span>Update</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateNewProptree;
