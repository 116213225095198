import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            feeds: [],
            feed: {},
            enterprise: {},
            error: ""
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            enterprise: this.props.enterprise,
            feeds: this.props.feeds
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.setState({
                feeds: nextProps.feeds,
                feed: {
                    customFilter: { id: 1, value: false, name: "Disable" },
                    customFilterInclude: { id: 0, value: true, name: "Include" },
                    customFilterEmpty: { id: 1, value: false, name: "False" },
                    enterprise: this.props.enterpriseId,
                    channel: { id: 1, name: "Facebook", value: "facebook" },
                    currency: this.props.enterpriseBaseCurrency,
                    language: this.props.enterpriseBaseLanguage,
                    dial_code: { id: 1, name: "None", value: "" },
                    double_qualify: { id: 1, name: 'Inherit', value: null },
                    version: nextProps.version,
                    type: { id: 1, name: 'Both', value: "both" },
                    custom: { id: 0, value: "disabled", name: "Disabled" },
                    property: { id: 1, name: 'On-plan & Off-plan', value: "both" },
                    size_unit: this.state.enterprise.adboosterType.value === 'boostabostad' ? { id: 2, name: "SQM", value: "sqm" } : { id: 1, name: "SQFT", value: "sqft" },
                    minPrice: 0,
                    maxPrice: 0
                },
                open: false,
            });
        }
        this.setState({
            open: nextProps.open
        })
    }

    functions = {
        createFeed: async () => {
            try {
                let response = await this.calls.createFeed({
                    name: this.state.feed.name,
                    theme: this.state.feed.theme,
                    utm: this.state.feed.utm,
                    enterprise: this.state.feed.enterprise,
                    channel: this.state.feed.channel.value,
                    currency: this.state.feed.currency.value,
                    language: this.state.feed.language.value,
                    dial_code: this.state.feed.dial_code.value,
                    double_qualify: this.state.feed.double_qualify.value,
                    type: this.state.feed.type.value,
                    property: this.state.feed.property.value,
                    minPrice: this.state.feed.minPrice,
                    maxPrice: this.state.feed.maxPrice,
                    custom: this.state.feed.custom,
                    size_unit: this.state.feed.size_unit,
                    customFilter: this.state.feed.customFilter.value,
                    customFilterKey: this.state.feed.customFilterKey,
                    customFilterValue: this.state.feed.customFilterValue,
                    customFilterInclude: this.state.feed.customFilterInclude.value,
                    customFilterEmpty: this.state.feed.customFilterEmpty.value,
                    live: this.state.feed.version.id == 1
                });
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggle();
                    if (this.props.onFeed) {
                        this.props.onFeed(response.data);
                    }
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        }
    }

    calls = {
        createFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed/create-feed";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-xl">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">

                                            <div className="py-5 px-4 bg-indigo-700 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-md font-medium text-white">Add feed</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between pt-6">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                Name extension
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.name = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.name ? this.state.feed.name : ""}
                                                                    type="text"
                                                                    name="feed-name"
                                                                    id="feed-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-utm-override" className="block text-sm font-medium text-gray-900">
                                                                Utm_feed override
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.utm = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.utm ? this.state.feed.utm : ""}
                                                                    type="text"
                                                                    name="feed-utm-override"
                                                                    id="feed-utm-override"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between pb-5">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-12">
                                                            <label htmlFor="feed-custom-filter" className="block text-sm font-medium text-gray-900">
                                                                Custom filter
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.customFilter = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.customFilter ? this.state.feed.customFilter : { id: 1, value: false, name: "Disable" }}
                                                                    options={[
                                                                        { id: 0, value: true, name: "Enable" },
                                                                        { id: 1, value: false, name: "Disable" }
                                                                    ]}
                                                                    name="feed-custom-filter"
                                                                    id="feed-custom-filter"
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-include" className="block text-sm font-medium text-gray-900">
                                                                    Include/Exclude
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.feed.customFilterInclude = option;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        selected={this.state.feed.customFilterInclude ? this.state.feed.customFilterInclude : { id: 0, value: true, name: "Include" }}
                                                                        options={[
                                                                            { id: 0, value: true, name: "Include" },
                                                                            { id: 1, value: false, name: "Exclude" }
                                                                        ]}
                                                                        name="feed-custom-filter-include"
                                                                        id="feed-custom-filter-include"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-key" className="block text-sm font-medium text-gray-900">
                                                                    Key
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.feed.customFilterKey = event.target.value;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        value={this.state.feed.customFilterKey ? this.state.feed.customFilterKey : ""}
                                                                        type="text"
                                                                        name="feed-custom-filter-key"
                                                                        id="feed-custom-filter-key"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3">
                                                                <label htmlFor="feed-custom-filter-empty" className="block text-sm font-medium text-gray-900">
                                                                    Empty value
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.feed.customFilterEmpty = option;
                                                                            if (option.value) {
                                                                                this.state.feed.customFilterValue = "";
                                                                            }
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        selected={this.state.feed.customFilterEmpty ? this.state.feed.customFilterEmpty : { id: 1, value: false, name: "False" }}
                                                                        options={[
                                                                            { id: 0, value: true, name: "True" },
                                                                            { id: 1, value: false, name: "False" }
                                                                        ]}
                                                                        name="feed-custom-filter-empty"
                                                                        id="feed-custom-filter-empty"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.customFilter && this.state.feed.customFilter.value &&
                                                            <div className="col-span-3 relative">
                                                                {
                                                                    this.state.feed.customFilterEmpty && this.state.feed.customFilterEmpty.value &&
                                                                    <div className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white bg-opacity-50"></div>
                                                                }
                                                                <label htmlFor="feed-custom-filter-value" className="block text-sm font-medium text-gray-900">
                                                                    Value
                                                                </label>
                                                                <div className="mt-1">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.feed.customFilterValue = event.target.value;
                                                                            this.setState({
                                                                                feed: this.state.feed
                                                                            })
                                                                        }}
                                                                        value={this.state.feed.customFilterValue ? this.state.feed.customFilterValue : ""}
                                                                        type="text"
                                                                        name="feed-custom-filter-value"
                                                                        id="feed-custom-filter-value"
                                                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-theme" className="block text-sm font-medium text-gray-900">
                                                                Theme override
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.theme = option;
                                                                        this.state.feed.custom = { id: 0, value: "disabled", name: "Disabled" };
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, value: "default", name: "Fallback theme" }}
                                                                    options={[
                                                                        {
                                                                            id: 0,
                                                                            value: "default",
                                                                            name: "Fallback theme"
                                                                        },
                                                                        {
                                                                            id: 1,
                                                                            value: "property_focus",
                                                                            custom: 'customThemePropertyInFocus',
                                                                            name: "Property in focus"
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            value: "agency_focus",
                                                                            custom: 'customThemeAgencyInFocus',
                                                                            name: "Agency in focus"
                                                                        },
                                                                        {
                                                                            id: 3,
                                                                            value: "broker_focus",
                                                                            custom: 'customThemeBrokerInFocus',
                                                                            name: "Agent in focus"
                                                                        },
                                                                        {
                                                                            id: 4,
                                                                            value: "promo_focus",
                                                                            custom: 'customThemePromoInFocus',
                                                                            name: "Promo in focus"
                                                                        },
                                                                        {
                                                                            id: 5,
                                                                            value: "basic_focus",
                                                                            custom: 'customThemeBasicInFocus',
                                                                            name: "Basic"
                                                                        }
                                                                    ]}
                                                                    name="feed-theme"
                                                                    id="feed-theme"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-custom-theme" className="block text-sm font-medium text-gray-900">
                                                                Custom
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={!(this.state.feed.theme && this.state.enterprise[this.state.feed.theme.custom] && this.state.enterprise[this.state.feed.theme.custom].value === 'enabled')}
                                                                    select={(option) => {
                                                                        this.state.feed.custom = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.custom ? this.state.feed.custom : { id: 0, value: "disabled", name: "Disabled" }}
                                                                    options={[
                                                                        {
                                                                            id: 0,
                                                                            value: "disabled",
                                                                            name: "Disabled"
                                                                        },
                                                                        {
                                                                            id: 1,
                                                                            value: "enabled",
                                                                            name: "Enabled"
                                                                        }
                                                                    ]}
                                                                    name="feed-custom-theme"
                                                                    id="feed-custom-theme"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                Channel
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.channel = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.channel}
                                                                    options={[
                                                                        { id: 1, name: 'Facebook', value: "facebook" },
                                                                        { id: 2, name: 'Google', value: "google" },
                                                                        { id: 3, name: 'Snapchat', value: "snapchat" },
                                                                        { id: 4, name: 'TikTok', value: "tiktok" }
                                                                    ]}
                                                                    name="feed-channel"
                                                                    id="feed-channel"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                Type
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.type = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.type}
                                                                    options={[
                                                                        { id: 1, name: 'Both', value: "both" },
                                                                        { id: 2, name: 'Sale only', value: "sale" },
                                                                        { id: 3, name: 'Rent only', value: "rent" },
                                                                    ]}
                                                                    name="feed-type"
                                                                    id="feed-type"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-plan" className="block text-sm font-medium text-gray-900">
                                                                Plan
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.property = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.property}
                                                                    options={[
                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                    ]}
                                                                    name="feed-plan"
                                                                    id="feed-plan"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-lang" className="block text-sm font-medium text-gray-900">
                                                                Language
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.language = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.language}
                                                                    options={[
                                                                        { id: 1, name: 'English', value: "en" },
                                                                        { id: 2, name: 'Swedish', value: "sv" },
                                                                        { id: 3, name: 'Arabic', value: "ar" },
                                                                        { id: 4, name: 'German', value: "de" },
                                                                        { id: 5, name: 'Spanish', value: "es" },
                                                                        { id: 6, name: 'French', value: "fr" },
                                                                        { id: 7, name: 'Russian', value: "ru" },
                                                                        { id: 8, name: 'Dutch', value: "nl" },
                                                                        { id: 9, name: 'Portuguese', value: "pt" },
                                                                        { id: 10, name: 'Italian', value: "it" },
                                                                    ]}
                                                                    name="feed-lang"
                                                                    id="feed-lang"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-currency" className="block text-sm font-medium text-gray-900">
                                                                Currency
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.currency = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.currency}
                                                                    options={[
                                                                        { id: 1, name: 'USD', value: "usd" },
                                                                        { id: 2, name: 'SEK', value: "sek" },
                                                                        { id: 3, name: 'AED', value: "aed" },
                                                                        { id: 4, name: 'EUR', value: "eur" },
                                                                        { id: 5, name: 'GBP', value: "gbp" },
                                                                        { id: 6, name: 'ILS', value: "ils" },
                                                                        { id: 7, name: 'AUD', value: "aud" },
                                                                        { id: 8, name: 'CHF', value: "chf" },
                                                                        { id: 9, name: 'ZAR', value: "zar" },
                                                                        { id: 10, name: 'NZD', value: "nzd" },
                                                                        { id: 11, name: 'INR', value: "inr" },
                                                                        { id: 12, name: 'CAD', value: "cad" },
                                                                        { id: 13, name: 'NOK', value: "nok" },
                                                                        { id: 14, name: 'DKK', value: "dkk" },
                                                                        { id: 15, name: 'SAR', value: "sar" },
                                                                        { id: 16, name: 'QAR', value: "qar" }
                                                                    ]}
                                                                    name="feed-currency"
                                                                    id="feed-currency"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-dial-code" className="block text-sm font-medium text-gray-900">
                                                                Country code
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.dial_code = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.dial_code}
                                                                    options={[
                                                                        { id: 1, name: 'None', value: "" },
                                                                        { id: 2, name: 'Denmark (+45)', value: "45" },
                                                                        { id: 3, name: 'France (+33)', value: "33" },
                                                                        { id: 4, name: 'Germany (+49)', value: "49" },
                                                                        { id: 5, name: 'Ireland (+353)', value: "353" },
                                                                        { id: 6, name: 'Norway (+47)', value: "47" },
                                                                        { id: 7, name: 'Sweden (+46)', value: "46" },
                                                                        { id: 8, name: 'Switzerland (+41)', value: "41" },
                                                                        { id: 9, name: 'UK (+44)', value: "44" },
                                                                        { id: 10, name: 'Australia (+61)', value: "61" },
                                                                        { id: 11, name: 'Canada/US (+1)', value: "1" },
                                                                        { id: 12, name: 'India (+91)', value: "91" },
                                                                        { id: 13, name: 'Israel (+972)', value: "972" },
                                                                        { id: 14, name: 'New Zealand (+64)', value: "64" },
                                                                        { id: 15, name: 'Qatar (+974)', value: "974" },
                                                                        { id: 16, name: 'Saudi Arabia (+966)', value: "966" },
                                                                        { id: 17, name: 'South Africa (+27)', value: "27" },
                                                                        { id: 18, name: 'UAE (+971)', value: "971" },
                                                                        { id: 19, name: 'Spain (+34)', value: "34" },
                                                                        { id: 20, name: 'Portugal (+351)', value: "351" },
                                                                        { id: 21, name: 'Netherlands (+31)', value: "31" },
                                                                        { id: 22, name: 'Italy (+39)', value: "39" }
                                                                    ]}
                                                                    name="feed-dial-code"
                                                                    id="feed-dial-code"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-size-unit" className="block text-sm font-medium text-gray-900">
                                                                Size unit
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.feed.size_unit = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.size_unit}
                                                                    options={[
                                                                        { id: 1, name: "SQFT", value: "sqft" },
                                                                        { id: 2, name: 'SQM', value: "sqm" }
                                                                    ]}
                                                                    name="feed-size-unit"
                                                                    id="feed-size-unit"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-qualify" className="block text-sm font-medium text-gray-900">
                                                                Double qualify
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    noLimit={true}
                                                                    select={(option) => {
                                                                        this.state.feed.double_qualify = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.double_qualify}
                                                                    options={[
                                                                        { id: 1, name: 'Inherit', value: null },
                                                                        { id: 2, name: 'Enable', value: "enable" },
                                                                        { id: 3, name: 'Disable', value: "disable" }
                                                                    ]}
                                                                    name="feed-qualify"
                                                                    id="feed-qualify"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-min-price" className="block text-sm font-medium text-gray-900">
                                                                Min price (optional)
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.minPrice = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.minPrice}
                                                                    type="number"
                                                                    name="feed-min-price"
                                                                    id="feed-min-price"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-max-price" className="block text-sm font-medium text-gray-900">
                                                                Max price (optional)
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.feed.maxPrice = event.target.value;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    value={this.state.feed.maxPrice}
                                                                    type="number"
                                                                    name="feed-max-price"
                                                                    id="feed-max-price"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="feed-version" className="block text-sm font-medium text-gray-900">
                                                                Version
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={true}
                                                                    select={(option) => {
                                                                        this.state.feed.version = option;
                                                                        this.setState({
                                                                            feed: this.state.feed
                                                                        })
                                                                    }}
                                                                    selected={this.state.feed.version}
                                                                    options={[
                                                                        { id: 1, name: 'DEV', value: "dev" },
                                                                        { id: 2, name: 'LIVE', value: "live" },
                                                                    ]}
                                                                    name="feed-version"
                                                                    id="feed-version"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            {
                                                !this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => {
                                                        this.props.toggle();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading) {
                                                        this.setState({
                                                            loading: true
                                                        }, () => {
                                                            this.functions.createFeed();
                                                        })
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateFeed;
