import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import queryString from "query-string";

class LoginToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    };

    componentDidMount() {
        this.functions.loginUser();
    }

    functions = {
        loginUser: async () => {
            try {

                let response = await this.calls.loginToken();
                userRegister.set(response.data);

                let query = {};
                try {
                    query = queryString.parse(window.location.search);
                } catch (error) {
                    query = {};
                }

                //ADMIN
                if (response.data.level && response.data.level.id === 1) {
                    this.props.history.push("/dashboard");
                }

                //AGENT -> MANAGER
                if (response.data.level && response.data.level.id === 2 && response.data.access && response.data.access.id === 1) {
                    if (window.location.search.indexOf("redirect=settings") !== -1) {
                        this.props.history.push("/manager/settings");
                    } else if (window.location.search.indexOf("redirect=leads") !== -1) {
                        if (window.location.search.indexOf("ranking=unrated") !== -1) {
                            if (window.location.search.indexOf("qualification=relevant") !== -1) {
                                this.props.history.push("/manager/leads?ranking=unrated&qualification=relevant");
                            } else if (window.location.search.indexOf("qualification=double_qualified") !== -1) {
                                this.props.history.push("/manager/leads?ranking=unrated&qualification=double_qualified");
                            } else {
                                this.props.history.push("/manager/leads?ranking=unrated");
                            }
                        } else {
                            this.props.history.push("/manager/leads");
                        }
                    } else {
                        this.props.history.push("/manager");
                    }
                }

                //AGENT -> REGULAR
                if (response.data.level && response.data.level.id === 2 && response.data.access && response.data.access.id === 2) {
                    if (query.lead) {
                        this.props.history.push("/agent/leads?lead=" + query.lead);
                    } else if (window.location.search.indexOf("redirect=leads") !== -1) {
                        if (window.location.search.indexOf("ranking=unrated") !== -1) {
                            if (window.location.search.indexOf("qualification=relevant") !== -1) {
                                this.props.history.push("/agent/leads?ranking=unrated&qualification=relevant");
                            } else if (window.location.search.indexOf("qualification=double_qualified") !== -1) {
                                this.props.history.push("/agent/leads?ranking=unrated&qualification=double_qualified");
                            } else {
                                this.props.history.push("/agent/leads?ranking=unrated");
                            }
                        } else {
                            this.props.history.push("/agent/leads");
                        }
                    } else {
                        this.props.history.push("/agent/leads");
                    }
                }

            } catch (error) {
                this.setState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                })
            }
        }
    };

    calls = {
        loginToken: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/user/login/" + this.props.match.params.user;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                {
                    this.state.loading &&
                    <div className="w-full h-full flex justify-center items-start bg-white">
                        <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.error &&
                    <div className="w-full h-full flex justify-center items-start bg-white">
                        <div className="w-full h-full absolute bg-white top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                            <div className="text-sm text-red-400 bg-red-100 p-4 rounded-md font-medium">
                                {this.state.message}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default LoginToken;

/*
if (response.data && response.data.level && response.data.level.id === 1) {
    this.props.history.push("/dashboard");
} else {
    if (response.data.access && response.data.access.id === 2) {

    } else {
        if (window.location.search.indexOf("redirect=leads") !== -1) {
            if (!(response.data.enterprise && response.data.enterprise.managerUIleads && response.data.enterprise.managerUIleads.value === "disabled")) {
                this.props.history.push("/manager/leads");
            } else {
                this.props.history.push("/manager/listings");
            }
        } else {
            if (!(response.data.enterprise && response.data.enterprise.managerUIdashboard && response.data.enterprise.managerUIdashboard.value === "disabled")) {
                this.props.history.push("/manager");
            } else {
                this.props.history.push("/manager/listings");
            }
        }
    }
}
*/
