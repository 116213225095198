import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateNewFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            adbooster_type: { id: 1, name: "Property Booster" },
            dontShowSubmitButton: false,
            preview: null,
            preview_params_missing: true
        }
    };

    componentDidMount() {

    }

    async componentWillReceiveProps(nextProps) {

        let user = userRegister.get();
        await this.promisedSetState({ user: user });
        if (!this.state.open && nextProps.open) {
            this.functions.getListings();
            this.state.feed = (nextProps.feed ? nextProps.feed : {});
            this.state.preview = null;
            this.state.listing = null;
            this.state.preview_params_missing = true;
        }

        let market = this.state.user.enterprise && this.state.user.enterprise.market && this.state.user.enterprise.market.value ? this.state.user.enterprise.market.value : "ae";
        let currency = this.state.user.enterprise && this.state.user.enterprise.baseCurrency && this.state.user.enterprise.baseCurrency.value ? this.state.user.enterprise.baseCurrency.value.toUpperCase() : "AED";

        this.setState({
            preview_params_missing: this.state.preview_params_missing,
            preview: this.state.preview,
            listing: this.state.listing,
            base_language: this.renders.localize(market, "base_language", currency),
            base_currency: this.renders.localize(market, "base_currency", currency),
            base_size_unit: this.renders.localize(market, "base_size_unit", currency),
            base_dial_code: this.renders.localize(market, "base_dial_code", currency),
            feed: this.state.feed,
            open: nextProps.open,
            user_enterprise: nextProps.enterprise,
            dontShowSubmitButton: nextProps.dontShowSubmitButton
        })
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            if (this.props.admin) {
                this.functions.getEnterprises();
            }
        },
        getEnterprises: async () => {
            try {
                await this.promisedSetState({ loading_enterprise: true });
                let response = await this.calls.getEnterprises();
                this.setState({
                    enterprises: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading: false,
                    loading_enterprise: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_enterprise: false
                })
            }
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data.map((item) => {
                        if (this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) {
                            item.name = item.projectName + "  #" + item.reference;
                        } else {
                            item.name = item.name + "  #" + item.reference;
                        }
                        return item;
                    })
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_listings: false });
        },
        getForms: async () => {
            try {
                await this.promisedSetState({ loading_forms: true });
                let response = await this.calls.getForms();
                this.setState({
                    forms: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading_forms: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_forms: false
                })
            }
        },
        createFeed: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let feed = JSON.parse(JSON.stringify(this.state.feed));
                if (this.renders.customDisabled()) {
                    feed.custom = { id: 0, name: 'Disabled', value: 'disabled' };
                }
                await this.calls.createFeed(feed);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        createPreview: async () => {
            try {
                if (this.state.listing) {
                    await this.promisedSetState({
                        preview: null,
                        loading_preview: true
                    });
                    let feed = JSON.parse(JSON.stringify(this.state.feed));
                    if (this.renders.customDisabled()) {
                        feed.custom = { id: 0, name: 'Disabled', value: 'disabled' };
                    }
                    if (feed.display_blur) {
                        feed.displayBlur = feed.display_blur;
                    }
                    if (feed.size_unit) {
                        feed.sizeUnit = feed.size_unit;
                    }
                    let response = await this.calls.createPreview({
                        listing: this.state.listing,
                        feeds: [feed]
                    });
                    await this.promisedSetState({
                        preview: response.data,
                        preview_params_missing: false
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_preview: false
            });
        },
        updateFeed: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let feed = JSON.parse(JSON.stringify(this.state.feed));
                await this.calls.updateFeed({
                    campaigns: feed.campaigns
                });
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        }
    };

    renders = {
        customDisabled: () => {
            //TODO: add settings for project templates enterprise settings and hide unit templates settings if project
            if (this.state.user_enterprise && this.state.user_enterprise.name) {
                //UNIT
                if (this.state.feed.theme && this.state.feed.theme.value === "property_focus" && this.state.user_enterprise.customThemePropertyInFocus && this.state.user_enterprise.customThemePropertyInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "agency_focus" && this.state.user_enterprise.customThemeAgencyInFocus && this.state.user_enterprise.customThemeAgencyInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "broker_focus" && this.state.user_enterprise.customThemeBrokerInFocus && this.state.user_enterprise.customThemeBrokerInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "promo_focus" && this.state.user_enterprise.customThemePromoInFocus && this.state.user_enterprise.customThemePromoInFocus.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "basic_focus" && this.state.user_enterprise.customThemeBasicInFocus && this.state.user_enterprise.customThemeBasicInFocus.value === "enabled") {
                    return false;
                }
                //PROJECT
                else if (this.state.feed.theme && this.state.feed.theme.value === "foundation" && this.state.user_enterprise.customThemeFoundation && this.state.user_enterprise.customThemeFoundation.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "essence" && this.state.user_enterprise.customThemeEssence && this.state.user_enterprise.customThemeEssence.value === "enabled") {
                    return false;
                } else if (this.state.feed.theme && this.state.feed.theme.value === "tonal" && this.state.user_enterprise.customThemeTonal && this.state.user_enterprise.customThemeTonal.value === "enabled") {
                    return false;
                } else {
                    return true;
                }
            } else if (this.state.feed.enterprise) {

            } else {
                return true;
            }
        },
        localize: (market, type, currency) => {

            //AVAILABLE MARKETS
            //TODO: add new markets here
            //TODO: some translations are done directly in form front-end, be sure to add support for new platform markets and langs also in front-end

            console.log(market + " " + type + " " + currency);

            // Default values
            let currency_before = true;
            let base_language = "en";
            let base_currency = currency || "AED";
            let base_size_unit = "sqft";
            let base_dial_code = "971";

            // Market-specific overrides
            if (market === "ae" || market === "uae") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "AED";
                base_size_unit = "sqft";
                base_dial_code = "971";
            } else if (market === "se") {
                currency_before = false;
                base_language = "sv";
                base_currency = currency || "SEK";
                base_size_unit = "sqm";
                base_dial_code = "46";
            } else if (market === "gb") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "GBP";
                base_size_unit = "sqft";
                base_dial_code = "44";
            }

            switch (type) {
                case "base_language":
                    return base_language;
                case "base_currency":
                    return base_currency.toUpperCase();
                case "base_size_unit":
                    return base_size_unit;
                case "base_dial_code":
                    return base_dial_code;
            }
        }
    };

    calls = {
        createFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed-light";
            return apiRegister.call(options, url);
        },
        createPreview: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/feed-light/creativeUrl";
            return apiRegister.call(options, url);
        },
        updateFeed: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/feed-light/campaigns/" + this.state.feed.id;
            return apiRegister.call(options, url);
        },
        getEnterprises: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id + "&type=" + this.state.adbooster_type.id;
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings";
            return apiRegister.call(options, url);
        },
        getForms: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/form?language=" + this.state.feed.language.value + "&channel=" + (this.state.feed.channel ? this.state.feed.channel.value : "");
            console.log(url);
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-7xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-row bg-white shadow-xl">

                                        <div className="md:flex hidden flex-1 flex-col">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                </div>
                                            </div>
                                            <div style={{ paddingTop: "34px", zIndex: "99" }} className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="col-span-12 sm:col-span-6 mb-4 md:lg-0">
                                                    <div className="space-y-6">
                                                        <div>
                                                            <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                Listing
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={async (option) => {
                                                                        this.state.listing = option;
                                                                        await this.promisedSetState({
                                                                            listing: this.state.listing
                                                                        });
                                                                        this.functions.createPreview();
                                                                    }}
                                                                    loader={this.state.loading_listings}
                                                                    selected={this.state.listing ? this.state.listing : { id: 0, name: 'Click to Select' }}
                                                                    options={this.state.listings}
                                                                    name="feed-channel"
                                                                    id="feed-channel"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                <div className="bg-gray-100 relative" style={{ width: "450px", height: "450px" }}>
                                                    {
                                                        this.state.preview_params_missing &&
                                                        <div className="w-full h-full text-sm flex items-center justify-center text-gray-700">
                                                            Select listing to see preview ...
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.preview_params_missing &&
                                                        //this.state.loading_preview &&
                                                        <div className="w-full h-full flex justify-center items-center flex-col">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            <div className="font-semibold text-sm mt-4 text-gray-700">Loading preview ...</div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.preview &&
                                                        <div className="absolute w-full h-full z-10 top-0">
                                                            <div className="w-full h-full relative">
                                                                <iframe src={this.state.preview + "&legacy=false"} width="450" height="450" frameborder="0" scrolling="no" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.feed.id ? "Update Feed" : "New Feed"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 h-0 overflow-y-auto">
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col pt-6">
                                                        {
                                                            this.props.admin &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 divide-y divide-gray-200">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="enterprise-name" className="block text-sm font-medium text-gray-900">
                                                                                Enterprise
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.feed.enterprise = option;
                                                                                        this.setState({
                                                                                            feed: this.state.feed
                                                                                        })
                                                                                    }}
                                                                                    loader={this.state.loading_enterprise}
                                                                                    selected={this.state.feed.enterprise ? this.state.feed.enterprise : { id: 0, name: 'Select enterprise' }}
                                                                                    options={this.state.enterprises}
                                                                                    name="enterprise-name"
                                                                                    id="enterprise-name"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 divide-y divide-gray-200">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div onClick={() => {
                                                                            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                                return navigator.clipboard.writeText(this.state.feed.filePath);
                                                                            }
                                                                        }}>
                                                                            <label htmlFor="feed-file-path" className="block text-sm font-medium text-gray-900">
                                                                                Feed URL (click to copy)
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={(event) => {

                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    value={this.state.feed.filePath ? this.state.feed.filePath : ""}
                                                                                    type="text"
                                                                                    name="feed-file-path"
                                                                                    id="feed-file-path"
                                                                                    className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" + (this.state.feed.id ? " bg-gray-100" : "")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="col-span-12 divide-y divide-gray-200">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="report-id" className="block text-sm font-medium text-gray-900">
                                                                                Link campaign Id's to filter leads (use comma to separate if more than one)
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        this.state.feed.campaigns = event.target.value;
                                                                                        this.setState({
                                                                                            feed: this.state.feed
                                                                                        })
                                                                                    }}
                                                                                    value={this.state.feed.campaigns ? this.state.feed.campaigns : ""}
                                                                                    type="text"
                                                                                    name="report-id"
                                                                                    id="report-id"
                                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 divide-y divide-gray-200">
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-name" className="block text-sm font-medium text-gray-900">
                                                                            Name
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.feed.name = event.target.value;
                                                                                    this.setState({
                                                                                        feed: this.state.feed
                                                                                    })
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                value={this.state.feed.name ? this.state.feed.name : ""}
                                                                                type="text"
                                                                                name="feed-name"
                                                                                id="feed-name"
                                                                                className={(this.state.feed.id ? "bg-gray-100 " : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-6 mb-4 md:lg-0">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                            Channel
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.channel = option;
                                                                                    delete this.state.feed.traffic;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.channel ? this.state.feed.channel : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'Meta', value: 'facebook' },
                                                                                    ...(this.state.user_enterprise &&
                                                                                    this.state.user_enterprise.managerUIproptree &&
                                                                                    this.state.user_enterprise.managerUIproptree.value === 'enabled'
                                                                                        ? [{ id: 2, name: 'Proptree', value: 'proptree' }]
                                                                                        : []),
                                                                                    // Uncomment other options as needed
                                                                                    // { id: 3, name: 'Google', value: 'google' },
                                                                                    // { id: 4, name: 'Snapchat', value: 'snapchat' },
                                                                                    // { id: 5, name: 'TikTok', value: 'tiktok' },
                                                                                ]}
                                                                                name="feed-channel"
                                                                                id="feed-channel"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                //not applicable light
                                                                false &&
                                                                <div className="col-span-12 sm:col-span-6">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="utm-override" className="block text-sm font-medium text-gray-900">
                                                                                Utm_feed override
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={async (event) => {
                                                                                        this.state.feed.utm = event.target.value;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    value={this.state.feed.utm ? this.state.feed.utm : ""}
                                                                                    type="text"
                                                                                    name="utm-override"
                                                                                    id="utm-override"
                                                                                    className={(this.state.feed.id ? "bg-gray-100 " : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-6 relative">
                                                                {
                                                                    !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="feed-theme" className="block text-sm font-medium text-gray-900">
                                                                            Theme
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.theme = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        {
                                                                                            id: 1,
                                                                                            value: "property_focus",
                                                                                            name: "Property in focus"
                                                                                        },
                                                                                        {
                                                                                            id: 2,
                                                                                            value: "agency_focus",
                                                                                            name: "Agency in focus"
                                                                                        },
                                                                                        {
                                                                                            id: 3,
                                                                                            value: "broker_focus",
                                                                                            name: "Agent in focus"
                                                                                        },
                                                                                        {
                                                                                            id: 4,
                                                                                            value: "promo_focus",
                                                                                            name: "Promo in focus"
                                                                                        },
                                                                                        {
                                                                                            id: 5,
                                                                                            value: "basic_focus",
                                                                                            name: "Basic"
                                                                                        },
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "basic_focus";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-theme"
                                                                                    id="feed-theme"
                                                                                />
                                                                            }
                                                                            {
                                                                                //TODO: add custom to this.renders.customDisabled()
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.theme = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.theme ? this.state.feed.theme : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        {
                                                                                            id: 200,
                                                                                            value: "foundation",
                                                                                            name: "Foundation"
                                                                                        },
                                                                                        {
                                                                                            id: 201,
                                                                                            value: "essence",
                                                                                            name: "Essence"
                                                                                        },
                                                                                        {
                                                                                            id: 202,
                                                                                            value: "tonal",
                                                                                            name: "Tonal"
                                                                                        },
                                                                                        /*
                                                                                        {
                                                                                            id: 203,
                                                                                            value: "mixed",
                                                                                            name: "Randomize"
                                                                                        }
                                                                                        */
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "foundation";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="theme"
                                                                                    id="theme"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-6 relative">
                                                                {
                                                                    !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className={this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 ? "space-y-6" : "space-y-6 pb-5"}>
                                                                    <div>
                                                                        <label htmlFor="feed-custom" className="block text-sm font-medium text-gray-900">
                                                                            Custom
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.custom = option;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id || this.renders.customDisabled()}
                                                                                selected={this.renders.customDisabled() ? { id: 0, name: 'Disabled', value: 'disabled' } : this.state.feed.custom ? this.state.feed.custom : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                options={[
                                                                                    { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                    { id: 1, name: 'Enabled', value: 'enabled' }
                                                                                ].filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == "disabled";
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-custom"
                                                                                id="feed-custom"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    {
                                                                        !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className={this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 ? "space-y-6" : "space-y-6 pb-5"}>
                                                                        <div>
                                                                            <label htmlFor="feed-extension" className="block text-sm font-medium text-gray-900">
                                                                                Extension
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.extension = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={(this.state.feed.channel && this.state.feed.channel.value == "proptree") || this.state.feed.id || (this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed")}
                                                                                    selected={this.state.feed.extension ? this.state.feed.extension : this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed" ? { id: 0, name: 'Mixed', value: 'disabled' } : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                    options={[
                                                                                        { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                        { id: 1, name: 'Agent', value: 'agent' }
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "disabled";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-extension"
                                                                                    id="feed-extension"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    {
                                                                        !(this.state.feed.channel && this.state.feed.channel.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="feed-blur" className="block text-sm font-medium text-gray-900">
                                                                                Blur
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.display_blur = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={(this.state.feed.channel && this.state.feed.channel.value == "proptree") || this.state.feed.id || (this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed")}
                                                                                    selected={this.state.feed.display_blur ? this.state.feed.display_blur : this.state.feed.displayBlur ? this.state.feed.displayBlur : this.state.feed.theme && this.state.feed.theme.value && this.state.feed.theme.value === "mixed" ? { id: 0, name: 'Mixed', value: 'disabled' } : { id: 0, name: 'Disabled', value: 'disabled' }}
                                                                                    options={[
                                                                                        { id: 0, name: 'Disabled', value: 'disabled' },
                                                                                        { id: 1, name: 'Enabled', value: 'enabled' }
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "disabled";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-blur"
                                                                                    id="feed-blur"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.theme && this.state.feed.theme.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-type" className="block text-sm font-medium text-gray-900">
                                                                            Type
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.type = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.type ? this.state.feed.type : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Sale & Rentals', value: "both" },
                                                                                        { id: 2, name: 'Sale', value: "sale" },
                                                                                        { id: 3, name: 'Rentals', value: "rent" }
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "both";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-type"
                                                                                    id="feed-type"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.type = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.type ? this.state.feed.type : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 2, name: 'Sale', value: "sale" }
                                                                                    ]}
                                                                                    name="feed-type"
                                                                                    id="feed-type"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.type && this.state.feed.type.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 sm:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-lang" className="block text-sm font-medium text-gray-900">
                                                                            Language
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.language = option;
                                                                                    delete this.state.feed.form;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.getForms();
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.language ? this.state.feed.language : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'English', value: "en" },
                                                                                    { id: 2, name: 'Swedish', value: "sv" },
                                                                                    { id: 3, name: 'Arabic', value: "ar" },
                                                                                    { id: 4, name: 'German', value: "de" },
                                                                                    { id: 5, name: 'Spanish', value: "es" },
                                                                                    { id: 6, name: 'French', value: "fr" },
                                                                                    { id: 7, name: 'Russian', value: "ru" },
                                                                                    { id: 8, name: 'Dutch', value: "nl" },
                                                                                    { id: 9, name: 'Portuguese', value: "pt" },
                                                                                    { id: 10, name: 'Italian', value: "it" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.value === this.state.base_language) return -1;
                                                                                    if (b.value === this.state.base_language) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.state.base_language.toLowerCase();
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-lang"
                                                                                id="feed-lang"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.language && this.state.feed.language.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-currency" className="block text-sm font-medium text-gray-900">
                                                                            Currency
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.currency = option;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.currency ? this.state.feed.currency : { id: 0, name: 'Click to Select' }}
                                                                                options={[
                                                                                    { id: 1, name: 'USD', value: "usd" },
                                                                                    { id: 2, name: 'SEK', value: "sek" },
                                                                                    { id: 3, name: 'AED', value: "aed" },
                                                                                    { id: 4, name: 'EUR', value: "eur" },
                                                                                    { id: 5, name: 'GBP', value: "gbp" },
                                                                                    { id: 6, name: 'ILS', value: "ils" },
                                                                                    { id: 7, name: 'AUD', value: "aud" },
                                                                                    { id: 8, name: 'CHF', value: "chf" },
                                                                                    { id: 9, name: 'ZAR', value: "zar" },
                                                                                    { id: 10, name: 'NZD', value: "nzd" },
                                                                                    { id: 11, name: 'INR', value: "inr" },
                                                                                    { id: 12, name: 'CAD', value: "cad" },
                                                                                    { id: 13, name: 'NOK', value: "nok" },
                                                                                    { id: 14, name: 'DKK', value: "dkk" },
                                                                                    { id: 15, name: 'SAR', value: "sar" },
                                                                                    { id: 16, name: 'QAR', value: "qar" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.value === this.state.base_currency) return -1;
                                                                                    if (b.value === this.state.base_currency) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.state.base_currency.toLowerCase() || item.value == "usd";
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-currency"
                                                                                id="feed-currency"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 lg:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-dial-code" className="block text-sm font-medium text-gray-900">
                                                                            Country code
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                noLimit={true}
                                                                                select={async (option) => {
                                                                                    this.state.feed.dial_code = option;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.dial_code ? this.state.feed.dial_code : { id: 1, name: 'None', value: "" }}
                                                                                options={[
                                                                                    { id: 1, name: 'None', value: "" },
                                                                                    { id: 2, name: 'Denmark (+45)', value: "45" },
                                                                                    { id: 3, name: 'France (+33)', value: "33" },
                                                                                    { id: 4, name: 'Germany (+49)', value: "49" },
                                                                                    { id: 5, name: 'Ireland (+353)', value: "353" },
                                                                                    { id: 6, name: 'Norway (+47)', value: "47" },
                                                                                    { id: 7, name: 'Sweden (+46)', value: "46" },
                                                                                    { id: 8, name: 'Switzerland (+41)', value: "41" },
                                                                                    { id: 9, name: 'UK (+44)', value: "44" },
                                                                                    { id: 10, name: 'Australia (+61)', value: "61" },
                                                                                    { id: 11, name: 'Canada/US (+1)', value: "1" },
                                                                                    { id: 12, name: 'India (+91)', value: "91" },
                                                                                    { id: 13, name: 'Israel (+972)', value: "972" },
                                                                                    { id: 14, name: 'New Zealand (+64)', value: "64" },
                                                                                    { id: 15, name: 'Qatar (+974)', value: "974" },
                                                                                    { id: 16, name: 'Saudi Arabia (+966)', value: "966" },
                                                                                    { id: 17, name: 'South Africa (+27)', value: "27" },
                                                                                    { id: 18, name: 'UAE (+971)', value: "971" },
                                                                                    { id: 19, name: 'Spain (+34)', value: "34" },
                                                                                    { id: 20, name: 'Portugal (+351)', value: "351" },
                                                                                    { id: 21, name: 'Netherlands (+31)', value: "31" },
                                                                                    { id: 22, name: 'Italy (+39)', value: "39" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.name === 'None') return -1;
                                                                                    if (b.name === 'None') return 1;
                                                                                    if (a.value === this.state.base_dial_code) return -1;
                                                                                    if (b.value === this.state.base_dial_code) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.state.base_dial_code;
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-dial-code"
                                                                                id="feed-dial-code"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-4 relative">
                                                                {
                                                                    (this.state.feed.dial_code && this.state.feed.dial_code.value !== "") || !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-size-unit" className="block text-sm font-medium text-gray-900">
                                                                            Size unit
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={async (option) => {
                                                                                    this.state.feed.size_unit = option;
                                                                                    await this.promisedSetState({
                                                                                        feed: this.state.feed
                                                                                    });
                                                                                    this.functions.createPreview();
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.size_unit ? this.state.feed.size_unit : this.state.feed.sizeUnit ? this.state.feed.sizeUnit : this.state.base_size_unit === "sqft" ? { id: 1, name: "SQFT", value: "sqft" } : { id: 2, name: 'SQM', value: "sqm" }}
                                                                                options={[
                                                                                    { id: 1, name: "SQFT", value: "sqft" },
                                                                                    { id: 2, name: 'SQM', value: "sqm" }
                                                                                ].sort((a, b) => {
                                                                                    if (a.value === this.state.base_size_unit) return -1;
                                                                                    if (b.value === this.state.base_size_unit) return 1;
                                                                                    return a.name.localeCompare(b.name);
                                                                                }).filter((item) => {
                                                                                    if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                        return item.value == this.state.base_size_unit.toLowerCase();
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                                name="feed-size-unit"
                                                                                id="feed-size-unit"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 flex flex-col justify-between">
                                                            <div className="px-4 relative sm:px-6">
                                                                {
                                                                    !(this.state.feed.currency && this.state.feed.currency.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-plan" className="block text-sm font-medium text-gray-900">
                                                                            Plan
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.property = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.property ? this.state.feed.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ].filter((item) => {
                                                                                        if (this.state.feed.channel && this.state.feed.channel.value == "proptree") {
                                                                                            return item.value == "both";
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    })}
                                                                                    name="feed-plan"
                                                                                    id="feed-plan"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.feed.property = option;
                                                                                        await this.promisedSetState({
                                                                                            feed: this.state.feed
                                                                                        });
                                                                                        this.functions.createPreview();
                                                                                    }}
                                                                                    disabled={this.state.feed.id}
                                                                                    selected={this.state.feed.property ? this.state.feed.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">

                                                            <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                {
                                                                    !(this.state.feed.property && this.state.feed.property.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 lg:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-ad-type" className="block text-sm font-medium text-gray-900">
                                                                            Ad
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.feed.traffic = option;
                                                                                    delete this.state.feed.qualification;
                                                                                    delete this.state.feed.form;
                                                                                    this.setState({
                                                                                        feed: this.state.feed
                                                                                    })
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.traffic ? this.state.feed.traffic : { id: 0, name: 'Click to Select' }}
                                                                                options={(this.state.feed.channel && this.state.feed.channel.value == "proptree" ? [
                                                                                    { id: 3, name: 'Proptree', value: "proptree" },
                                                                                ] : [
                                                                                    { id: 1, name: 'Regular ads', value: "regular_ads" },
                                                                                    { id: 2, name: 'Lead ads', value: 'lead_ads' },
                                                                                ])}
                                                                                name="feed-ad-type"
                                                                                id="feed-ad-type"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                {
                                                                    !(this.state.feed.traffic && this.state.feed.traffic.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 lg:pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-traffic" className="block text-sm font-medium text-gray-900">
                                                                            Traffic
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.feed.qualification = option;
                                                                                    delete this.state.feed.form;
                                                                                    this.setState({
                                                                                        feed: this.state.feed
                                                                                    })
                                                                                }}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.qualification ? this.state.feed.qualification : { id: 0, name: 'Click to Select' }}
                                                                                options={this.state.feed.traffic && this.state.feed.traffic.value === 'lead_ads' ? [
                                                                                    //{ id: 2, name: 'WhatsApp -> Redirect', value: 'listing_site' },
                                                                                    { id: 3, name: 'WhatsApp -> Form', value: 'form_site' },
                                                                                    //{ id: 4, name: 'Form', value: 'form_only' }
                                                                                ] : [
                                                                                    //{ id: 2, name: 'Unlock -> Redirect', value: 'listing_site' },
                                                                                    { id: 3, name: 'Unlock -> Form', value: 'form_site' }
                                                                                ]}
                                                                                name="feed-traffic"
                                                                                id="feed-traffic"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"sm:col-span-4 col-span-12 relative"}>
                                                                {
                                                                    !(this.state.feed.qualification && this.state.feed.qualification.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6 pb-5">
                                                                    <div>
                                                                        <label htmlFor="feed-form" className="block text-sm font-medium text-gray-900">
                                                                            Form
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <SingleSelection
                                                                                select={(option) => {
                                                                                    this.state.feed.form = option;
                                                                                    this.setState({
                                                                                        feed: this.state.feed
                                                                                    })
                                                                                }}
                                                                                loader={this.state.loading_forms}
                                                                                disabled={this.state.feed.id}
                                                                                selected={this.state.feed.form ? this.state.feed.form : { id: 0, name: 'Select form', value: "none" }}
                                                                                options={this.state.forms.map((item) => { return { name: item.name, id: item.id } })}
                                                                                name="feed-form"
                                                                                id="feed-form"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className="px-6 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                                {
                                                    !this.state.dontShowSubmitButton &&
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.feed.id) {
                                                                this.functions.updateFeed();
                                                            } else {
                                                                if (this.state.feed.traffic && this.state.feed.traffic.value && this.state.feed.qualification && this.state.feed.qualification.value && this.state.feed.form) {
                                                                    this.functions.createFeed();
                                                                }
                                                            }
                                                        }}
                                                        className={(this.state.feed.traffic && this.state.feed.traffic.value && this.state.feed.qualification && this.state.feed.qualification.value && this.state.feed.form ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        {
                                                            this.state.loading &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.feed.id &&
                                                            <span>Create</span>
                                                        }
                                                        {
                                                            this.state.feed.id &&
                                                            <span>Update</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateNewFeed;
