import { EyeIcon, CursorClickIcon, ArrowRightIcon, ExternalLinkIcon, OfficeBuildingIcon, SelectorIcon, PlayIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import React, { Component, Fragment } from 'react';
import Dropdown from '../components/dropdown';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';

class LinktreePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init_listings: true,
            loading_listings: true,
            listings: [],
            agent: {},
            page: 1,
            areas: [],
            area: { id: 1, name: "All areas", count: 0 },
            property_types: [],
            property_type: { id: 1, name: "All types", count: 0 },
            filter: { id: 1, name: "All listings", value: 'total', count: 0 },
            sort: { id: 1, name: "Newest", value: 'createdAt' },
            limit: 8,
            orderBy: 'descending',
            sortBy: 'createdAt',
            total: 0,
            banner: false,
            hover_contact: false,
            platform_base_color: "667EEA"
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            agent: this.props.user
        });
        this.functions.getProptreeListings(true);
    }

    async componentWillReceiveProps(nextProps) {

    }

    functions = {
        getListings: async (init) => {
            await this.promisedSetState({ loading_listings: true, init_listings: true, listings: init ? [] : this.state.listings });
            try {
                let response = await this.calls.getListings();
                if (response.meta && response.meta.areas) {
                    response.meta.areas.sort(function (a, b) {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    let total = 0;
                    response.meta.areas = response.meta.areas.map((item) => { total += item.count; return { id: item.name, name: item.name, count: item.count } });
                    response.meta.areas = [{ id: 1, name: "All areas", count: total }].concat(response.meta.areas);
                }
                if (response.meta && response.meta.property_types) {
                    response.meta.property_types.sort(function (a, b) {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    let total = 0;
                    response.meta.property_types = response.meta.property_types.map((item) => { total += item.count; return { id: item.name, name: item.name + (item.name.toLowerCase().indexOf('bed') !== -1 ? " Apartment " : ""), count: item.count } });
                    response.meta.property_types = [{ id: 1, name: "All types", count: total }].concat(response.meta.property_types);
                }

                //SET BANNER
                if (!init && !this.state.banner) {
                    this.state.banner = true;
                    response.data = [{ primary_color: this.state.agent.enterprise.primaryColor, theme_image: this.state.agent.enterprise.themeImage, name: this.state.agent.enterprise.name, banner: true }].concat(response.data);
                }

                await this.promisedSetState({
                    banner: this.state.banner,
                    listings: init ? response.data : this.state.listings.concat(response.data),
                    total: response.meta ? response.meta.total : 0,
                    sales_rentals: response.meta ? response.meta.sales_rentals : 0,
                    rentals: response.meta ? response.meta.rentals : 0,
                    sales: response.meta ? response.meta.sales : 0,
                    areas: response.meta ? response.meta.areas : [],
                    property_types: response.meta ? response.meta.property_types : []
                });
            } catch (error) { }
            await this.promisedSetState({ loading_listings: false });
        },
        getProptreeListings: async (init) => {
            await this.promisedSetState({ loading_listings: true, listings: init ? [] : this.state.listings });
            try {
                let response = await this.calls.getProptreeListings();
                if (response.meta && response.meta.areas) {
                    response.meta.areas.sort(function (a, b) {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    let total = 0;
                    response.meta.areas = response.meta.areas.map((item) => { total += item.count; return { id: item.name, name: item.name, count: item.count } });
                    response.meta.areas = [{ id: 1, name: "All areas", count: total }].concat(response.meta.areas);
                }
                if (response.meta && response.meta.property_types) {
                    response.meta.property_types.sort(function (a, b) {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    let total = 0;
                    response.meta.property_types = response.meta.property_types.map((item) => { total += item.count; return { id: item.name, name: item.name + (item.name.toLowerCase().indexOf('bed') !== -1 ? " Apartment " : ""), count: item.count } });
                    response.meta.property_types = [{ id: 1, name: "All types", count: total }].concat(response.meta.property_types);
                }

                //SET BANNER
                if (!init && !this.state.banner) {
                    this.state.banner = true;
                    response.data = [{ primary_color: this.state.agent.enterprise.primaryColor, theme_image: this.state.agent.enterprise.themeImage, name: this.state.agent.enterprise.name, banner: true }].concat(response.data);
                }

                await this.promisedSetState({
                    banner: this.state.banner,
                    listings: init ? response.data : this.state.listings.concat(response.data),
                    total: response.meta ? response.meta.total : 0,
                    sales_rentals: response.meta ? response.meta.sales_rentals : 0,
                    rentals: response.meta ? response.meta.rentals : 0,
                    sales: response.meta ? response.meta.sales : 0,
                    areas: response.meta ? response.meta.areas : [],
                    property_types: response.meta ? response.meta.property_types : []
                });
            } catch (error) { }
            await this.promisedSetState({ loading_listings: false, init_listings: false });
        }
    };

    calls = {
        getListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/listings?profile=" + this.state.agent.id + "&enterprise=" + this.state.agent.enterprise.id + "&page=" + this.state.page + "&limit=" + this.state.limit + "&sortBy=" + this.state.sort.value + "&filter=" + this.state.filter.value + "&area=" + this.state.area.name + "&propertyType=" + this.state.property_type.name.replace(" Apartment ", "");
            return apiRegister.call(options, url);
        },
        getProptreeListings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.proptree + "/getProptreeListings/" + this.props.alias + "?page=" + this.state.page + "&limit=" + this.state.limit + "&sortBy=" + this.state.sort.value + "&filter=" + this.state.filter.value + "&area=" + this.state.area.name + "&propertyType=" + this.state.property_type.name.replace(" Apartment ", "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        price: (num) => {
            try {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            } catch (error) {
                console.log(error);
                return num;
            }
        },
        getListingUrl: (listing) => {
            if (this.state.agent.enterprise && this.state.agent.enterprise.legacy && this.state.agent.enterprise.legacy.value === "enabled") {
                return "https://form.adbooster.ai/" + this.state.agent.form + "?utm_feed=" + this.state.agent.feed + "&utm_listing=" + listing.externalId + "&utm_origin=proptree&utm_origin_id=" + this.props.alias;
            } else {
                let utm = "";
                if (this.state.agent.enterprise && this.state.agent.enterprise.adboosterType) {
                    utm = "?utm_source=adBooster&utm_medium=proptree&utm_saleschannel=" + this.state.agent.enterprise.adboosterType.value;
                }
                if (this.state.agent.enterprise && this.state.agent.enterprise.adboosterType && this.state.agent.enterprise.adboosterType.value === 'boostabostad') {
                    if (this.state.agent.enterprise.version && (this.state.agent.enterprise.version.id === 1 || this.state.agent.enterprise.version.value === "live")) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId + utm;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId + utm;
                    }
                } else {
                    if (this.state.agent.enterprise && this.state.agent.enterprise.version && (this.state.agent.enterprise.version.id === 1 || this.state.agent.enterprise.version.value === "live")) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId + utm;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId + utm;
                    }
                }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        const customStyles = {
            singleValue: (provided, state) => ({
                ...provided,
                color: state.isSelected ? this.state.agent.enterprise.primaryColor : provided.color
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? this.state.agent.enterprise.primaryColor : provided.backgroundColor,
                color: state.isSelected ? '#fff' : provided.color
            })
        };

        return (
            <div className="h-full min-h-full px-4 sm:px-0">

                {/*HEADER*/}
                {
                    <div className="flex relative pb-12 justify-center items-center sm:pt-10 px-8">
                        <div className="flex-col sm:flex-row flex items-center" onMouseEnter={() => {
                            this.setState({
                                hover_contact: true
                            });
                        }}
                            onMouseLeave={() => {
                                this.setState({
                                    hover_contact: false
                                });
                            }}
                            onTouchStart={() => {
                                this.setState({
                                    hover_contact: true
                                });
                            }}
                            onTouchEnd={() => {
                                this.setState({
                                    hover_contact: false
                                });
                            }}>
                            <div className={(!this.state.agent.image ? "animate-pulse bg-gray-200 " : "") + "mb-4 sm:mb-0 w-40 h-40 border-1.5 rounded-full border bg-gray-200 overflow-hidden"}>
                                {
                                    //TODO: on agency page display agency bio here
                                    !this.state.loading_listings && this.state.hover_contact &&
                                    <div style={{ maxWidth: "200px" }} className="absolute bg-gray-800 text-white -mt-6 z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                        To get in touch, select a listing and begin your inquiry
                                        <div className="absolute" style={{
                                            bottom: '-8px',
                                            left: "14px",
                                            width: 0,
                                            height: 0,
                                            borderLeft: "10px solid transparent",
                                            borderRight: "10px solid transparent",
                                            borderTop: "10px solid #343338"
                                        }}></div>
                                    </div>
                                }
                                {
                                    this.state.agent.image &&
                                    <img src={this.state.agent.image} className="w-full h-full object-cover" />
                                }
                            </div>
                            <div className="sm:ml-6 flex flex-col items-center sm:items-start">
                                {
                                    !this.state.agent.name &&
                                    <div class="animate-pulse inline-block bg-gray-200 text-gray-200 text-opacity-0 rounded-md font-bold leading-tight text-xl sm:text-2xl justify-center items-center text-left flex flex-row">Loading name ...</div>
                                }
                                {
                                    this.state.agent.name &&
                                    <div className="font-bold leading-tight text-xl sm:text-2xl justify-center items-center text-left flex flex-row">
                                        {this.state.agent.name}
                                        {
                                            this.state.agent && this.state.agent.enterprise &&
                                            <svg className="w-4 sm:w-5 ml-1 sm:ml-2 mt-1"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true">
                                                <defs />
                                                <g
                                                    fill={this.state.agent && this.state.agent.enterprise && this.state.agent.enterprise.primaryColor ? this.state.agent.enterprise.primaryColor : this.state.platform_base_color}
                                                >
                                                    <path
                                                        d="M22.25 12c0-1.43-.88-2.67-2.19-3.34.46-1.39.2-2.9-.81-3.91s-2.52-1.27-3.91-.81c-.66-1.31-1.91-2.19-3.34-2.19s-2.67.88-3.33 2.19c-1.4-.46-2.91-.2-3.92.81s-1.26 2.52-.8 3.91c-1.31.67-2.2 1.91-2.2 3.34s.89 2.67 2.2 3.34c-.46 1.39-.21 2.9.8 3.91s2.52 1.26 3.91.81c.67 1.31 1.91 2.19 3.34 2.19s2.68-.88 3.34-2.19c1.39.45 2.9.2 3.91-.81s1.27-2.52.81-3.91c1.31-.67 2.19-1.91 2.19-3.34zm-11.71 4.2L6.8 12.46l1.41-1.42 2.26 2.26 4.8-5.23 1.47 1.36-6.2 6.77z"
                                                        fill={this.state.agent && this.state.agent.enterprise && this.state.agent.enterprise.primaryColor ? this.state.agent.enterprise.primaryColor : this.state.platform_base_color}
                                                    />
                                                </g>
                                            </svg>
                                        }
                                    </div>
                                }
                                {
                                    !(this.state.agent.enterprise && this.state.agent.enterprise.name) &&
                                    <div class="animate-pulse inline-block bg-gray-200 text-gray-200 text-opacity-0 rounded-md font-medium text-gray-500 text-s sm:text-lg sm:text-left">Loading name ...</div>
                                }
                                {
                                    this.state.agent.enterprise && this.state.agent.enterprise.name &&
                                    <div className="font-medium text-gray-500 text-s sm:text-lg sm:text-left">
                                        {this.state.agent.enterprise ? this.state.agent.enterprise.name : ""}
                                    </div>
                                }
                                <div className="flex flex-col">
                                    <div className="flex text-xs flex-row mt-3 font-medium text-gray-500">
                                        {
                                            this.state.init_listings &&
                                            <div className="mr-2">
                                                <span className="animate-pulse inline-block bg-gray-200 text-gray-200 text-opacity-0 rounded-md font-semibold text-gray-700">Loading ...</span>
                                            </div>
                                        }
                                        {
                                            this.state.total > 0 &&
                                            <div className="mr-2">
                                                <span className="font-semibold text-gray-700">{this.state.total > 0 ? this.state.total + " listings" : ""}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*BUTTONS*/}
                {
                    false &&
                    Array.isArray(this.state.agent.buttons) && this.state.agent.buttons.length > 0 &&
                    <div className="sm:px-8 max-w-sm mx-auto mb-10">
                        <a href={""} target={"_blank"}>
                            <div className="h-12 bg-white relative mb-4 rounded-md shadow border cursor-pointer justify-center items-center px-6 flex flex-row">
                                WhatsApp Me
                                <div className="absolute opacity-0 flex justify-end items-center pr-4 left-0 right-0 top-0 bottom-0 hover:opacity-100">
                                    <ExternalLinkIcon className="h-5 w-5" />
                                </div>
                            </div>
                        </a>
                    </div>
                }

                {
                    <div className="max-w-sm sm:max-w-8xl mx-auto mb-10 sm:block">
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="col-span-1 grid grid-cols-6 sm:grid-cols-5 lg:grid-cols-6 gap-4 mb-4 sm:mb-0">
                                {
                                    !(this.state.agent.enterprise && this.state.agent.enterprise.parserType && this.state.agent.enterprise.parserType.value.indexOf("project") !== -1) &&
                                    this.state.agent.enterprise && this.state.agent.enterprise.primaryColor &&
                                    <div className="col-span-3 sm:col-span-2 max-w-sm">
                                        <Dropdown
                                            label={"Filter"}
                                            locked={this.state.loading_listings}
                                            proptree={true}
                                            selected={this.state.filter}
                                            options={[
                                                { id: 1, name: "All listings", value: 'all', count: this.state.sales_rentals },
                                                { id: 2, name: "Sales", value: 'sales', count: this.state.sales },
                                                { id: 3, name: "Rentals", value: 'rentals', count: this.state.rentals }
                                            ]}
                                            searchInput={false}
                                            onChange={async (option) => {
                                                await this.promisedSetState({
                                                    filter: option,
                                                    page: 1,
                                                    banner: false
                                                });
                                                this.functions.getProptreeListings(true);
                                            }}
                                            selectedTextColor={this.state.agent.enterprise.primaryColor}
                                        />
                                    </div>
                                }
                                {
                                    !(this.state.agent.enterprise && this.state.agent.enterprise.parserType && this.state.agent.enterprise.parserType.value.indexOf("project") !== -1) &&
                                    this.state.agent.enterprise && this.state.agent.enterprise.primaryColor &&
                                    <div className="col-span-3 sm:col-span-2 max-w-sm">
                                        <Dropdown
                                            label={"Type"}
                                            locked={this.state.loading_listings}
                                            proptree={true}
                                            selected={this.state.property_type}
                                            options={this.state.property_types}
                                            searchInput={true}
                                            onChange={async (option) => {
                                                await this.promisedSetState({
                                                    property_type: option,
                                                    page: 1,
                                                    banner: false
                                                });
                                                this.functions.getProptreeListings(true);
                                            }}
                                            selectedTextColor={this.state.agent.enterprise.primaryColor}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="col-span-1 grid grid-cols-6 sm:grid-cols-5 lg:grid-cols-6 gap-4">
                                {
                                    this.state.agent.enterprise && this.state.agent.enterprise.primaryColor &&
                                    <div className="col-span-3 sm:col-span-2 max-w-sm lg:col-start-3 sm:col-start-2">
                                        <Dropdown
                                            label={"Area"}
                                            locked={this.state.loading_listings}
                                            proptree={true}
                                            selected={this.state.area}
                                            options={this.state.areas}
                                            searchInput={true}
                                            onChange={async (option) => {
                                                await this.promisedSetState({
                                                    area: option,
                                                    page: 1,
                                                    banner: false
                                                });
                                                this.functions.getProptreeListings(true);
                                            }}
                                            selectedTextColor={this.state.agent.enterprise.primaryColor}
                                        />
                                    </div>
                                }
                                {
                                    this.state.agent.enterprise && this.state.agent.enterprise.primaryColor &&
                                    <div className="col-span-3 sm:col-span-2 max-w-sm">
                                        <div className="w-full">
                                            <Dropdown
                                                label={"Sort"}
                                                locked={this.state.loading_listings}
                                                proptree={true}
                                                selected={this.state.sort}
                                                options={[
                                                    { id: 1, name: "Newest", value: 'createdAt' },
                                                    { id: 2, name: "Highest price ", value: 'higest_price' },
                                                    { id: 3, name: "Lowest price ", value: 'lowest_price' },
                                                    { id: 4, name: "Bedrooms", value: 'bedrooms' }
                                                ]}
                                                searchInput={false}
                                                onChange={async (option) => {
                                                    await this.promisedSetState({
                                                        sort: option,
                                                        page: 1,
                                                        banner: false
                                                    });
                                                    this.functions.getProptreeListings(true);
                                                }}
                                                selectedTextColor={this.state.agent.enterprise.primaryColor}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    <div className="flex items-center flex-col max-w-sm sm:max-w-8xl mx-auto">
                        <div className="grid grid-cols-1 gap-8 sm:gap-10 md:gap-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {
                                (this.state.listings.length > 0 ? this.state.listings : [{ skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }, { skeleton: true }]).map((item, index) => {
                                    return (
                                        <Fragment>
                                            {
                                                !item.banner &&
                                                <a href={this.renders.getListingUrl(item)} target="_blank">
                                                    <div className="col-span-1 cursor-pointer max-w-sm">
                                                        <div className="relative">
                                                            {
                                                                !item.skeleton &&
                                                                <div className="absolute p-3">
                                                                    <div style={{ backgroundColor: this.state.agent.enterprise.primaryColor }} className={(item.forRent ? "" : "") + " text-white inline-flex items-center flex-row px-2 py-1 leading-none rounded-sm font-semibold uppercase tracking-wide text-xs"}>
                                                                        {item.projectName ? (item.developer ? item.developer.toUpperCase() : item.completionYear && item.completionQuarter ? item.completionYear + "." + item.completionQuarter : "PROJECT") : (item.forRent ? "FOR RENT" : "FOR SALE")}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "bg-gray-200") + " w-full h-64 rounded-md"}>
                                                                {
                                                                    !item.skeleton &&
                                                                    <img className="inset-0 h-full rounded-md w-full object-cover" src={item.imagesInternal[0]} />
                                                                }
                                                                {
                                                                    item.skeleton &&
                                                                    <div className="inset-0 h-full rounded-md w-full" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-12 mt-2 gap-2">
                                                            <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "bg-gray-200") + " col-span-3 h-16 rounded-md"}>
                                                                {
                                                                    !item.skeleton &&
                                                                    <img className="inset-0 h-full w-full rounded-md object-cover" src={item.imagesInternal[1]} />
                                                                }
                                                                {
                                                                    item.skeleton &&
                                                                    <div className="inset-0 h-full w-24 rounded-md" />
                                                                }
                                                            </div>
                                                            <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "bg-gray-200") + " col-span-3 h-16 rounded-md"}>
                                                                {
                                                                    !item.skeleton &&
                                                                    <img className="inset-0 h-full w-full rounded-md object-cover" src={item.imagesInternal[2]} />
                                                                }
                                                                {
                                                                    item.skeleton &&
                                                                    <div className="inset-0 h-full w-24 rounded-md" />
                                                                }
                                                            </div>
                                                            <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "bg-gray-200") + " flex relative items-center justify-center col-span-3 h-16 rounded-md"}>
                                                                {
                                                                    /* <PlayIcon className="absolute w-5 h-5 left-1/2 top-1/2 transform"></PlayIcon> */
                                                                    !item.skeleton && item.webTour && item.webTour !== "" &&
                                                                    <div className="absolute w-6 h-6 left-1/2 top-1/2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#151517" className="size-6">
                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                                                        </svg>
                                                                    </div>
                                                                }
                                                                {
                                                                    !item.skeleton &&
                                                                    <img className={(item.webTour && item.webTour !== "" ? "opacity-25 " : "") + "inset-0 h-full w-full rounded-md object-cover"} src={item.imagesInternal[3]} />
                                                                }
                                                                {
                                                                    item.skeleton &&
                                                                    <div className="inset-0 h-full w-24 rounded-md" />
                                                                }
                                                            </div>
                                                            <div className={(item.skeleton ? "animate-pulse bg-gray-200" : "border") + " rounded-md col-span-3 text-sm flex-col flex justify-center items-center h-16"}>
                                                                {
                                                                    !item.skeleton &&
                                                                    <div className="font-medium">
                                                                        {(item.imagesAmount - 3 > 0) ? (item.imagesAmount - 3) + "+" : "-"}
                                                                    </div>
                                                                }
                                                                {
                                                                    false && !item.skeleton &&
                                                                    <div className="font-medium text-xxs">
                                                                        images
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            {
                                                                item.skeleton &&
                                                                <div class="animate-pulse inline-block px-2 py-1 bg-gray-200 text-gray-200 text-opacity-0 rounded-md font-semibold uppercase tracking-wide text-xs">Loading description ...</div>
                                                            }
                                                            {
                                                                !item.skeleton &&
                                                                <h4 class="text-gray-900 font-semibold text-lg">{item.projectName ? item.projectName + " " : ((item.adboosterIndustry && item.adboosterIndustry.name.toLowerCase().indexOf('bed for') !== -1 || item.adboosterIndustry && item.adboosterIndustry.name.toLowerCase().indexOf('studio') !== -1) ? "Apartment " : item.adboosterIndustry.name.replace("For Rent", "").replace("For Sale", ""))}&#8226;<span className=""> {item.area} </span>&#8226;<span className=""> {item.city}</span></h4>
                                                            }
                                                            {
                                                                item.skeleton &&
                                                                <div className="mt-1">
                                                                    <div class="animate-pulse inline-block px-2 py-1 bg-gray-200 text-gray-200 text-opacity-0 rounded-md font-semibold uppercase tracking-wide text-xs">Loading property facts and information ...</div>
                                                                </div>
                                                            }
                                                            {
                                                                !item.skeleton &&
                                                                <div class="text-xs text-gray-600 font-semibold uppercase tracking-wide">
                                                                    {item.projectName ? (item.toBedrooms ? (item.bedrooms < 1 ? ("studio to " + item.toBedrooms + " bed") : (item.bedrooms + " bed to " + item.toBedrooms + " bed")) : ("from " + (item.bedrooms < 1 ? "studio" : item.bedrooms + " bed"))) : (item.bedrooms < 1 ? "studio" : item.bedrooms + " bed")} • {item.projectName ? "" : (item.bathrooms ? item.bathrooms + " bath • " : "")}{item.projectName ? (item.toSize ? (item.size + " to " + item.toSize + " sqft • ") : ("from " + item.size + " sqft • ")) : (item.size + " sqft • ")}{item.projectName ? (item.toPrice ? (item.currency + " " + this.renders.price(item.price) + " to " + this.renders.price(item.toPrice)) : ("from " + item.currency + " " + this.renders.price(item.price))) : (item.currency + " " + this.renders.price(item.price))} {item.forRent && <span className="capitalize"> {item.rentalPeriod.toUpperCase()}</span>}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </a>
                                            }
                                            {
                                                item.banner &&
                                                <div style={{ backgroundColor: item.primary_color, borderColor: item.primary_color }} className="relative border-1.5 overflow-hidden mb-4 rounded-lg col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4">
                                                    <div className="relative md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
                                                        {
                                                            false &&
                                                            <div className="absolute flex justify-center items-center left-0 right-0 top-0 bottom-0 z-10 bg-purple-700 bg-opacity-75">
                                                                <div className="w-64">
                                                                    <img
                                                                        className="h-full w-full object-cover"
                                                                        src=""
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        <img
                                                            className="w-full object-cover"
                                                            src={item.theme_image}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="relative mx-auto max-w-7xl py-8 lg:px-8">
                                                        <div className="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
                                                            <p className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{item.name}</p>
                                                            <h2 className="text-sm font-semibold leading-7 text-white">395 Listings {"&"} 123 Agents</h2>
                                                            <div className="mt-8">
                                                                <div onClick={() => {
                                                                    //TODO: add link to agency page
                                                                }} className="bg-white bg-opacity-25 items-center cursor-pointer justify-center text-sm font-semibold text-white p-2 px-4 rounded-lg inline-flex flex-row focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                                                    <div>Check it out</div>
                                                                    <ArrowRightIcon className="w-4 h-4 ml-2"></ArrowRightIcon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {
                    this.state.listings.length > 0 &&
                    this.state.listings.length < this.state.total &&
                    <div className="sm:px-8 max-w-sm mx-auto mt-12">
                        <div onClick={async () => {
                            if (!this.state.loading_listings) {
                                await this.promisedSetState({
                                    page: this.state.page + 1
                                });
                                this.functions.getProptreeListings();
                            }
                        }} className="h-10 rounded bg-white text-sm border relative overflow-hidden cursor-pointer justify-center items-center px-6 flex flex-row">
                            <div>Load more</div>
                            {
                                this.state.loading_listings &&
                                <div className=" absolute bg-white left-0 right-0 top-0 bottom-0 flex justify-center items-center">
                                    <div style={{ borderColor: this.state.agent.enterprise.primaryColor, borderTopColor: "transparent" }}
                                        class="w-5 h-5 border-2 border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="h-20 w-full">

                    </div>
                }

                {
                    <div className="w-full text-center justify-center items-start flex">
                        <a target="_blank" href="https://proptr.ee">
                            {
                                false &&
                                <img className="w-16 h-auto" src={require("../assets/images/Proptree_Logotype_RGB_Black.png")} />
                            }
                            <svg className="w-16 h-auto"
                                viewBox="0 0 564.85333 122.42667"
                                aria-hidden="true">
                                <defs />
                                <g
                                    transform="matrix(1.3333333,0,0,-1.3333333,0,122.42667)"
                                ><g
                                    transform="scale(0.1)">
                                        <path
                                            d="M 519.961,294.656 370.707,366.23 c -10.996,5.274 -23.789,5.243 -34.734,-0.078 L 189.48,294.746 c -30.835,-15.039 -63.433,17.266 -48.625,48.176 L 318.074,712.52 c 13.016,27.113 51.61,27.371 65.067,0.39 l 0.004,0.024 184.839,-369.575 c 15.45,-30.808 -16.921,-63.613 -48.023,-48.703 z M 684.215,635.73 391.188,902.828 c -22.395,20.547 -56.723,20.547 -79.118,0 L 19.0469,635.73 C 6.91797,624.598 0.00390625,608.867 0,592.383 V 252.539 C 0,220.078 26.2344,193.758 58.6016,193.758 H 644.648 c 32.368,0 58.61,26.32 58.61,58.781 v 339.844 c -0.012,16.484 -6.918,32.215 -19.043,43.347"
                                            fill={this.state.agent && this.state.agent.enterprise && this.state.agent.enterprise.primaryColor ? this.state.agent.enterprise.primaryColor : this.state.platform_base_color}
                                        />
                                        <path
                                            d="m 1214.43,528.004 c -19.81,0 -38.69,-5.149 -56.13,-15.305 -17.48,-10.172 -31.73,-25.066 -42.36,-44.281 -10.62,-19.16 -16.01,-41.926 -16.01,-67.664 0,-25.754 5.39,-48.52 16.01,-67.668 10.62,-19.207 24.87,-34.113 42.36,-44.285 17.45,-10.16 36.34,-15.305 56.13,-15.305 20.32,0 39.48,5.281 56.94,15.703 17.43,10.41 31.66,25.418 42.31,44.629 10.62,19.18 16.01,42.195 16.01,68.418 0,25.723 -5.39,48.379 -16.02,67.328 -10.66,18.973 -24.92,33.614 -42.39,43.531 -17.46,9.883 -36.58,14.899 -56.85,14.899 z M 1004.93,0 v 603.5 h 95 v -70.664 l 9.08,12.769 c 13,18.29 31.22,33.809 54.16,46.118 22.9,12.297 49.49,18.531 79.02,18.531 34.39,0 65.94,-8.59 93.77,-25.524 27.81,-16.941 50.16,-41.386 66.41,-72.656 16.3,-31.386 24.56,-68.34 24.56,-109.828 0,-41.504 -8.27,-78.711 -24.58,-110.598 -16.26,-31.785 -38.61,-56.738 -66.45,-74.16 -27.81,-17.41 -59.34,-26.238 -93.71,-26.238 -29.51,0 -55.84,6.102 -78.26,18.152 -22.52,12.11 -41.03,27.539 -55.02,45.879 l -8.98,11.762 V 0 h -95"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="M 1513.43,198.008 V 603.5 h 95 v -77.195 l 9.26,15.082 c 13,21.179 30.2,38.011 51.11,50.031 19.59,11.254 42.15,17.301 67.13,18.016 v -100.18 h -22.75 c -34.14,0 -60.39,-8.274 -78.02,-24.582 -17.74,-16.41 -26.73,-44.899 -26.73,-84.672 V 198.008 h -95"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="m 1997.42,528.754 c -31.27,0 -57.85,-11.246 -79,-33.422 -21.07,-22.125 -31.75,-53.945 -31.75,-94.578 0,-40.625 10.41,-72.434 30.96,-94.535 20.63,-22.207 46.96,-33.465 78.29,-33.465 19.78,0 38.64,4.875 56.04,14.492 17.52,9.688 31.69,24.363 42.11,43.621 10.35,19.141 15.6,42.656 15.6,69.887 0,40.652 -10.95,72.488 -32.55,94.617 -21.64,22.152 -48.45,33.383 -79.7,33.383 z m -1.5,-337.504 c -38.98,0 -74.49,8.75 -105.57,25.996 -31.04,17.219 -55.76,41.934 -73.45,73.457 -17.74,31.57 -26.73,68.602 -26.73,110.051 0,41.402 9.23,78.418 27.45,110 18.21,31.547 43.42,56.266 74.94,73.484 31.62,17.262 67.4,26.016 106.36,26.016 38.94,0 74.72,-8.754 106.35,-26.016 31.53,-17.222 56.74,-41.949 74.94,-73.484 18.22,-31.582 27.46,-68.598 27.46,-110 0,-41.399 -9.49,-78.391 -28.19,-109.953 -18.67,-31.555 -44.27,-56.289 -76.07,-73.535 C 2071.56,200 2035.4,191.25 1995.92,191.25"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="m 2504.41,528.004 c -19.81,0 -38.7,-5.149 -56.14,-15.305 -17.47,-10.172 -31.73,-25.066 -42.35,-44.281 -10.62,-19.16 -16.01,-41.926 -16.01,-67.664 0,-25.754 5.39,-48.52 16.01,-67.668 10.62,-19.207 24.87,-34.113 42.36,-44.285 17.45,-10.16 36.34,-15.305 56.13,-15.305 20.32,0 39.48,5.281 56.94,15.703 17.43,10.41 31.67,25.418 42.31,44.629 10.63,19.18 16.01,42.195 16.01,68.418 0,25.723 -5.39,48.379 -16.02,67.328 -10.66,18.973 -24.92,33.614 -42.39,43.531 -17.46,9.883 -36.58,14.899 -56.85,14.899 z M 2294.91,0 v 603.5 h 95 v -70.664 l 9.07,12.769 c 13.01,18.29 31.23,33.809 54.17,46.118 22.9,12.297 49.49,18.531 79.02,18.531 34.39,0 65.94,-8.59 93.77,-25.524 27.81,-16.941 50.16,-41.386 66.41,-72.656 16.3,-31.386 24.56,-68.34 24.56,-109.828 0,-41.504 -8.27,-78.711 -24.58,-110.598 -16.26,-31.785 -38.62,-56.738 -66.45,-74.16 -27.81,-17.41 -59.34,-26.238 -93.71,-26.238 -29.51,0 -55.84,6.102 -78.26,18.152 -22.52,12.11 -41.03,27.539 -55.02,45.879 l -8.98,11.762 V 0 h -95"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="m 2947.66,198.008 c -85.96,0 -127.75,40.555 -127.75,123.996 V 527.25 h -49.5 v 76.25 h 49.5 v 102.754 h 95.75 V 603.5 h 93 v -76.25 h -93 v -206 c 0,-15.383 3.95,-26.836 11.72,-34.043 7.79,-7.199 20.06,-10.703 37.53,-10.703 h 43.75 v -78.496 h -61"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="M 3094.39,198.008 V 603.5 h 95 v -77.195 l 9.26,15.082 c 13.01,21.179 30.21,38.011 51.12,50.031 19.59,11.254 42.14,17.301 67.12,18.016 v -100.18 h -22.74 c -34.14,0 -60.39,-8.274 -78.03,-24.582 -17.73,-16.41 -26.73,-44.899 -26.73,-84.672 V 198.008 h -95"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="m 3570.9,534.004 c -27.16,0 -50.35,-8.375 -68.95,-24.887 -20.19,-17.918 -30.45,-42.527 -34.28,-82.301 l -0.53,-5.476 h 207.81 l -0.06,5.055 c -0.49,39.843 -9.47,63.609 -30.99,82.031 -19.87,16.972 -44.43,25.578 -73,25.578 z m 3,-342.754 c -38.97,0 -74.36,8.738 -105.19,25.984 -30.78,17.207 -55.11,41.907 -72.3,73.411 -17.27,31.601 -26.02,68.64 -26.02,110.109 0,41.949 8.63,79.262 25.65,110.883 16.96,31.476 41.02,56.039 71.54,72.996 30.6,17.004 66.38,25.621 106.32,25.621 38.45,0 73.35,-8.375 103.73,-24.899 30.25,-16.46 54.06,-40.144 70.77,-70.394 16.75,-30.363 25.24,-65.758 25.24,-105.215 0,-12.902 -0.77,-24.816 -2.28,-35.488 h -304.9 l 0.45,-5.418 c 2.6,-31.164 13.73,-55.988 33.11,-73.774 19.35,-17.793 43.44,-26.812 71.62,-26.812 39.8,0 68.82,16.902 86.32,50.242 h 103.34 c -12.14,-35.93 -34.04,-66.055 -65.18,-89.629 -32.95,-24.961 -74.07,-37.617 -122.22,-37.617"
                                            fill={"#000000"}
                                        />
                                        <path
                                            d="m 4033.63,534.004 c -27.16,0 -50.35,-8.375 -68.94,-24.887 -20.2,-17.918 -30.45,-42.527 -34.29,-82.301 l -0.53,-5.476 h 207.82 l -0.06,5.055 c -0.49,39.843 -9.48,63.609 -31,82.031 -19.86,16.972 -44.42,25.578 -73,25.578 z m 3,-342.754 c -38.96,0 -74.35,8.738 -105.18,25.984 -30.79,17.207 -55.11,41.907 -72.31,73.411 -17.26,31.601 -26.01,68.64 -26.01,110.109 0,41.949 8.63,79.262 25.65,110.883 16.95,31.476 41.01,56.039 71.53,72.996 30.61,17.004 66.38,25.621 106.32,25.621 38.46,0 73.35,-8.375 103.73,-24.899 30.26,-16.46 54.06,-40.144 70.77,-70.394 16.75,-30.363 25.25,-65.758 25.25,-105.215 0,-12.902 -0.77,-24.816 -2.29,-35.488 H 3929.2 l 0.45,-5.418 c 2.6,-31.164 13.73,-55.988 33.1,-73.774 19.35,-17.793 43.44,-26.812 71.63,-26.812 39.79,0 68.81,16.902 86.31,50.242 h 103.34 c -12.14,-35.93 -34.04,-66.055 -65.17,-89.629 -32.95,-24.961 -74.07,-37.617 -122.23,-37.617"
                                            fill={"#000000"}
                                        />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                }

                {
                    <div className="h-2 w-full">

                    </div>
                }

                {
                    <div className="flex left-0 right-0 text-xxs text-gray-600 justify-center items-center">
                        <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/privacy-policy">Privacy Policy</a>
                        <div className="ml-1 mr-1 text-xxs">•</div>
                        <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/terms">Terms</a>
                        <div className="ml-1 mr-1 text-xxs">•</div>
                        <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/use-policy">Use Policy</a>
                    </div>
                }

                {
                    <div className="h-20 w-full">

                    </div>
                }

            </div>
        )
    }
}

export default LinktreePage;
