import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateDislayLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            user: {},
            version: {},
            rows: { id: 1, name: '3', value: 3 },
            type: { id: 1, name: 'Grid', value: "grid" },
            feed: {},
            duration: { id: 3, name: '100s', value: 100 },
            background_color: "ffffff"
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            feed: nextProps.feed ? nextProps.feed : {},
        })
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            if (user && user.enterprise) {
                await this.promisedSetState({ background_color: user.enterprise.color.replace("#", "") });
            }
            let version = versionRegister.get();
            if (version) {
                await this.promisedSetState({ version: version });
            }
        }
    }

    renders = {
    }

    calls = {
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{"Display link"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col h-0 overflow-y-auto">
                                            <div className="flex flex-row">
                                                <div className="flex flex-1 flex-col pt-6">
                                                    <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                        <div className="col-span-12 sm:col-span-6">
                                                            <div className="space-y-6">
                                                                <div>
                                                                    <label htmlFor="link-type" className="block text-sm font-medium text-gray-900">
                                                                        Type
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.setState({
                                                                                    type: option
                                                                                })
                                                                            }}
                                                                            selected={this.state.type}
                                                                            options={[
                                                                                { id: 1, name: 'Grid', value: "grid" }
                                                                            ]}
                                                                            name="link-type"
                                                                            id="link-type"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-6">
                                                            <div className="space-y-6">
                                                                <div>
                                                                    <label htmlFor="link-rows" className="block text-sm font-medium text-gray-900">
                                                                        Rows
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.setState({
                                                                                    rows: option
                                                                                })
                                                                            }}
                                                                            selected={this.state.rows}
                                                                            options={[
                                                                                { id: 1, name: '3', value: 3 },
                                                                                { id: 2, name: '4', value: 4 },
                                                                                { id: 3, name: '5', value: 5 },
                                                                                { id: 4, name: '6', value: 6 },
                                                                                { id: 5, name: '7', value: 7 },
                                                                                { id: 6, name: '8', value: 8 }
                                                                            ]}
                                                                            name="link-rows"
                                                                            id="link-rows"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-6">
                                                            <div className="space-y-6">
                                                                <div>
                                                                    <label htmlFor="link-duration" className="block text-sm font-medium text-gray-900">
                                                                        Speed
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={(option) => {
                                                                                this.setState({
                                                                                    duration: option
                                                                                })
                                                                            }}
                                                                            selected={this.state.duration}
                                                                            options={[
                                                                                { id: 1, name: '25s', value: 25 },
                                                                                { id: 2, name: '50s', value: 50 },
                                                                                { id: 3, name: '100s', value: 100 },
                                                                                { id: 4, name: '150s', value: 150 },
                                                                                { id: 5, name: '200s', value: 200 }
                                                                            ]}
                                                                            name="link-duration"
                                                                            id="link-duration"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-12 sm:col-span-6">
                                                            <div className="space-y-6">
                                                                <div>
                                                                    <label htmlFor="color_primary" className="block mb-1 text-sm font-medium text-gray-900">
                                                                        Background color
                                                                    </label>
                                                                    <div style={{ height: "39px" }} className={"w-full border border-gray-300 rounded-md shadow-sm"}>
                                                                        <div className='overflow-hidden flex w-full flex-row rounded-md h-full'>
                                                                            <div className={"h-full px-2 flex justify-center items-center bg-gray-50 border-r"}>#</div>
                                                                            <div
                                                                                style={{}}
                                                                                className="flex flex-1 items-center h-full">
                                                                                <input
                                                                                    className="border-none p-2 text-sm outline-none flex-1 flex h-full bg-transparent"
                                                                                    type="text"
                                                                                    placeholder=''
                                                                                    value={this.state.background_color}
                                                                                    onClick={(e) => {
                                                                                        this.setState({ selectedId: e.target.id })
                                                                                    }}
                                                                                    onChange={async (e) => {
                                                                                        e.target.value = e.target.value.replace(/#/g, "");
                                                                                        await this.promisedSetState({
                                                                                            background_color: e.target.value
                                                                                        })
                                                                                    }}
                                                                                    name="color_primary"
                                                                                    id="color_primary"
                                                                                />
                                                                            </div>
                                                                            <div className="relative w-32 h-full overflow-hidden">
                                                                                <input
                                                                                    className="w-full h-full absolute p-1 bg-white cursor-pointer"
                                                                                    type="color"
                                                                                    value={"#" + this.state.background_color}
                                                                                    onChange={async (e) => {
                                                                                        let color_string = e.target.value.slice(1);
                                                                                        await this.promisedSetState({
                                                                                            background_color: color_string
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col sm:px-6 mt-6">
                                                <div htmlFor="link-preview" className="block mb-1 md:ml-0 ml-4 text-sm font-medium text-gray-900">
                                                    Preview
                                                </div>
                                                <div className="border shadow-sm bg-gray-50 flex-1 flex overflow-hidden">
                                                    <iframe
                                                        src={"http://localhost:5001/display-grid?background_color=" + this.state.background_color + "&rows=" + this.state.rows.value + "&feed=" + this.state.feed.id + "&duration=" + this.state.duration.value}
                                                        className="w-full h-full">
                                                    </iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                            <div
                                                onClick={() => {
                                                    let url = "";
                                                    if (window.location.hostname === 'localhost') {
                                                        url = "http://localhost:5001/";
                                                    } else if (window.location.hostname === 'app.adbooster.se') {
                                                        url = "https://app.adbooster.se/";
                                                    } else if (window.location.hostname === 'app.adbooster.ai') {
                                                        url = "https://app.adbooster.ai/";
                                                    }
                                                    if (this.state.type.value == 'grid') {
                                                        url = url + "display-grid";
                                                    } else if (this.state.type.value == 'map') {
                                                        url = url + "display-map";
                                                    }
                                                    url = url + "?background_color=" + this.state.background_color;
                                                    url = url + "&rows=" + this.state.rows.value;
                                                    url = url + "&feed=" + this.state.feed.id;
                                                    window.open(url);
                                                }}
                                                className={" bg-purple-500 hover:bg-purple-600 cursor-pointer ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                            >
                                                <span>Open link</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateDislayLink;
