import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            user: {},
            message: ""
        }
    };

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.state.user = (nextProps.user ? nextProps.user : {

            });
        }
        this.setState({
            user: this.state.user,
            open: nextProps.open
        })
    }

    functions = {
        createClient: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let response = await this.calls.createClient(this.state.user);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUser) {
                    this.props.onUser(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateClient: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.updateClient(this.state.user);
                await this.promisedSetState({ loading: false });
                this.props.toggle();
                if (this.props.onUpdate) {
                    this.props.onUpdate(response.data);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        }
    }

    calls = {
        createClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/client/createClient";
            return apiRegister.call(options, url);
        },
        updateClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/client/updateClient?id=" + this.state.user.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-4xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="py-5 pb-2 bg-white px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.user.id ? "Update Client" : "New Client"}</Dialog.Title>
                                                <div className="ml-3 h-10 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="client-name" className="block text-sm font-medium text-gray-900">
                                                                Name
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.name = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.name}
                                                                    type="text"
                                                                    name="client-name"
                                                                    id="client-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="client-email" className="block text-sm font-medium text-gray-900">
                                                                Email
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.email = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.email}
                                                                    type="email"
                                                                    name="client-email"
                                                                    id="client-email"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="client-phone" className="block text-sm font-medium text-gray-900">
                                                                Phone
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.state.user.phone = event.target.value;
                                                                        this.setState({
                                                                            user: this.state.user
                                                                        })
                                                                    }}
                                                                    value={this.state.user.phone}
                                                                    type="phone"
                                                                    name="client-phone"
                                                                    id="client-phone"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                false &&
                                                <div className="flex-1 flex flex-col justify-between">
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="client-country" className="block text-sm font-medium text-gray-900">
                                                                    Country
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.user.country = option;
                                                                            this.setState({
                                                                                user: this.state.user
                                                                            })
                                                                        }}
                                                                        selected={this.state.user.country ? this.state.user.country : { id: 0, name: "Unknown" }}
                                                                        options={[
                                                                            { id: 1, name: 'Sweden' },
                                                                            { id: 2, name: 'Denmark' },
                                                                            { id: 0, name: "Unknown" }
                                                                        ]}
                                                                        name="client-country"
                                                                        id="client-country"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {
                                                false &&
                                                <div className="grid grid-cols-12 gap-4 px-4  sm:px-6">
                                                    <div className="divide-y divide-gray-200 col-span-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="client-gender" className="block text-sm font-medium text-gray-900">
                                                                    Gender
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.user.gender = option;
                                                                            this.setState({
                                                                                user: this.state.user
                                                                            })
                                                                        }}
                                                                        selected={this.state.user.gender ? this.state.user.gender : { id: 0, name: "Unknown" }}
                                                                        options={[
                                                                            { id: 1, name: 'Male' },
                                                                            { id: 2, name: 'Female' },
                                                                            { id: 0, name: "Unknown" }
                                                                        ]}
                                                                        name="client-gender"
                                                                        id="client-gender"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="divide-y divide-gray-200 col-span-6">
                                                        <div className="space-y-6 pb-5">
                                                            <div>
                                                                <label htmlFor="client-age" className="block text-sm font-medium text-gray-900">
                                                                    Age
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={(option) => {
                                                                            this.state.user.age = option;
                                                                            this.setState({
                                                                                user: this.state.user
                                                                            })
                                                                        }}
                                                                        selected={this.state.user.age ? this.state.user.age : { id: 0, name: "Unknown" }}
                                                                        options={[
                                                                            { id: 18, name: '18' },
                                                                            { id: 19, name: '19' },
                                                                            { id: 0, name: "Unknown" }
                                                                        ]}
                                                                        name="client-age"
                                                                        id="client-age"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                this.state.error &&
                                                <div className="px-6 pb-6">
                                                    <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <ul role="list" className="list-disc pl-5 space-y-1">
                                                                        <li>{this.state.error}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-shrink-0 px-6 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.state.user.id) {
                                                        this.functions.updateClient();
                                                    } else {
                                                        this.functions.createClient();
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.user.id &&
                                                    <span>Create</span>
                                                }
                                                {
                                                    this.state.user.id &&
                                                    <span>Update</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateClient;
