import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateNewFeed from '../../components/createNewFeed';
import CreateNewReport from '../../components/createNewReport';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            meta: {},
            open: false,
            tab: { id: 'all', name: "All reports", value: 'all', count: 0 },
            reports: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'created' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            loading_feeds: false
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.getReports(true);
    }

    functions = {
        getUser: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
        },
        getReports: async (init) => {
            try {
                await this.promisedSetState({
                    loading: init,
                    loading_feeds: !init
                });
                let response = await this.calls.getReports();
                this.setState({
                    total: response.meta.total,
                    reports: response.data,
                    meta: response.meta,
                    loading: false,
                    loading_feeds: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        removeReport: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                reports: this.state.reports.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeReport(selected_feed.id);
                await this.promisedSetState({
                    reports: this.state.reports.filter((item) => { return selected_feed.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    reports: this.state.reports.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        }
    };

    calls = {
        getReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/report-light?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.tab.status;
            return apiRegister.call(options, url);
        },
        removeReport: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/report-light/" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        channels: (item) => {
            let channels = {};
            item.campaigns.map((item) => {
                if (item.channel && item.channel.value) {
                    channels[item.channel.value] = true;
                }
            });
            return Object.keys(channels);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.admin ? "p-6 " : "sm:p-0 p-6 pt-0 ") + " grid grid-cols-12 h-full min-h-full bg-gray-50"}>

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading reports ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Report"}
                    description={"Are you sure you want to delete this report? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeReport();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*CREATE USER*/}
                <CreateNewReport
                    version={this.state.version}
                    admin={this.props.admin}
                    report={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onCreate={() => {
                        this.functions.getReports();
                    }}
                    onUpdate={() => {
                        this.functions.getReports();
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-2 mb-4 md:mb-6">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 'all', name: "All reports", value: 'all', count: this.state.meta.all }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getReports();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-6"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'created', adsOnly: false }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getReports();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getReports();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg rounded-lg">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        {
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className="text-sm font-medium cursor-pointer items-center flex flex-row text-purple-500 hover:text-purple-700">
                                                <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                Create new report
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.reports.length < 1 &&
                                        <div className="h-110 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_feeds &&
                                                <div className="font-medium text-sm">No Reports available</div>
                                            }
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.reports.length > 0 &&
                                        <div className="relative overflow-x-scroll min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listings</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Channel</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Theme</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Type</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Language</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Currency</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Property</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.reports.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        {
                                                                            this.props.admin &&
                                                                            <td className="flex items-center px-6 py-4">
                                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                                    <img className="h-10 w-10 rounded-full" src={agent.enterprise ? agent.enterprise.logo : ""} alt="" />
                                                                                </div>
                                                                                <div className="ml-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.enterprise ? agent.enterprise.name : ""}</div>
                                                                                    <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">

                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.props.admin &&
                                                                            <td className="flex items-center px-6 py-4">
                                                                                {
                                                                                    this.renders.channels(agent).map((item, index) => {
                                                                                        return (
                                                                                            <div className={(index !== 0 ? "-ml-4" : "") + " bg-" + item + "-500 flex-shrink-0 flex justify-center items-center overflow-hidden rounded-full h-10 w-10 border border-white"}>
                                                                                                {
                                                                                                    item === 'facebook' &&
                                                                                                    <div>
                                                                                                        <img src={require('../../assets/images/AB_Social_facebook.png')} alt="" className="w-2" />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    item === 'google' &&
                                                                                                    <div>
                                                                                                        <img src={require('../../assets/images/AB_Social_google.png')} alt="" className="w-4" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">

                                                                        </td>
                                                                        {
                                                                            !this.props.admin &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name ? agent.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Name</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.campaigns ? agent.campaigns.filter((item) => { return item.channel && item.channel.value === 'facebook' }).length : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Facebook</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.campaigns ? agent.campaigns.filter((item) => { return item.channel && item.channel.value === 'google' }).length : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Google</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.campaigns ? agent.campaigns.filter((item) => { return item.channel && item.channel.value === 'snapchat' }).length : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Snapchat</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.campaigns ? agent.campaigns.filter((item) => { return item.channel && item.channel.value === 'tiktok' }).length : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Tiktok</div>
                                                                        </td>
                                                                        <td className="text-right px-6">
                                                                            <div className="flex flex-row justify-end">
                                                                                <div
                                                                                    onClick={async () => {

                                                                                    }}
                                                                                    className={"cursor-pointer mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                >
                                                                                    <div>Open</div>
                                                                                </div>
                                                                                <div
                                                                                    onClick={async () => {
                                                                                        this.setState({
                                                                                            open: true,
                                                                                            selected: agent
                                                                                        })
                                                                                    }}
                                                                                    className={"cursor-pointer mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                >
                                                                                    <div>Edit</div>
                                                                                </div>
                                                                                <div
                                                                                    onClick={async () => {
                                                                                        if (!agent.loading) {
                                                                                            this.setState({
                                                                                                remove: agent
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    className={"cursor-pointer relative inline-flex items-center px-4 py-2 border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"}
                                                                                >
                                                                                    <div>Remove</div>
                                                                                    {
                                                                                        agent.loading &&
                                                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.reports.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    this.state.reports.length > 0 &&
                                                    <div className="hidden sm:block">
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state.total}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getReports();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getReports();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getReports();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListReports;
