import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateEnterprise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            open: false,
            status: { id: 1, name: 'Active' },
            version: { id: 1, name: 'Live' },
            adboosterType: { id: 1, name: 'Property Booster', value: 'propertybooster', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643467035/xsvt4uklb3evikmdhxca.png" },
            error: ""
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            version: this.props.version
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.setState({
                name: "",
                open: false,
                status: { id: 1, name: 'Active' },
                version: nextProps.version,
            });
        }
        this.setState({
            open: nextProps.open
        })
    }

    functions = {
        createEnterprise: async () => {
            try {
                let response = await this.calls.createEnterprise({
                    name: this.state.name,
                    status: this.state.status,
                    version: this.state.version,
                    adboosterType: this.state.adboosterType,
                });
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggle();
                    if (this.props.onEnterprise) {
                        this.props.onEnterprise(response.data);
                    }
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        }
    }

    calls = {
        createEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/enterprise/create";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">

                                            <div className="py-5 px-4 bg-indigo-700 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-md font-medium text-white">New Enterprise</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="enterprise-name" className="block text-sm font-medium text-gray-900">
                                                                Name
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            name: event.target.value
                                                                        })
                                                                    }}
                                                                    value={this.state.name}
                                                                    type="text"
                                                                    name="enterprise-name"
                                                                    id="enterprise-name"
                                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="enterprise-type" className="block text-sm font-medium text-gray-900">
                                                                adBooster type
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.setState({
                                                                            adboosterType: option
                                                                        })
                                                                    }}
                                                                    selected={this.state.adboosterType}
                                                                    options={[
                                                                        { id: 1, name: 'Property Booster', value: 'propertybooster', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643467035/xsvt4uklb3evikmdhxca.png" },
                                                                        { id: 2, name: 'Yieldlock', value: 'yieldlock', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643467021/w5tbkagflubbiqb0oilq.png" },
                                                                        { id: 3, name: 'Boosta Bostad', value: 'boostabostad', image: "https://res.cloudinary.com/propertybooster/image/upload/v1643466967/aauqgg4ldqwtktxt5tdt.png" },
                                                                    ]}
                                                                    name="enterprise-type"
                                                                    id="enterprise-type"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="enterprise-version" className="block text-sm font-medium text-gray-900">
                                                                Version
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={true}
                                                                    select={(option) => {
                                                                        this.setState({
                                                                            version: option
                                                                        })
                                                                    }}
                                                                    selected={this.state.version}
                                                                    options={[
                                                                        { id: 1, name: 'Live' },
                                                                        { id: 2, name: 'Dev' },
                                                                    ]}
                                                                    name="enterprise-version"
                                                                    id="enterprise-version"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex flex-col justify-between">
                                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                    <div className="space-y-6 pb-5">
                                                        <div>
                                                            <label htmlFor="enterprise-status" className="block text-sm font-medium text-gray-900">
                                                                Status
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.setState({
                                                                            status: option
                                                                        })
                                                                    }}
                                                                    selected={this.state.status}
                                                                    options={[
                                                                        { id: 1, name: 'Active' },
                                                                        { id: 2, name: 'Disabled' },
                                                                    ]}
                                                                    name="enterprise-status"
                                                                    id="enterprise-status"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            {
                                                !this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => {
                                                        this.props.toggle();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading) {
                                                        this.setState({
                                                            loading: true
                                                        }, () => {
                                                            this.functions.createEnterprise();
                                                        })
                                                    }
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateEnterprise;
