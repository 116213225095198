import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CursorClickIcon, MenuIcon, ShareIcon, XIcon } from '@heroicons/react/outline'
import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import LinktreePage from './page';

const navigation = [
    //{ name: 'Listings', link: '/agent/listings' },
    //{ name: 'Leads', link: '/agent/leads' }
];

class ProptreeMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            listings: [],
            sidebarOpen: false,
            version: {},
            linkCopied: false,
            loading: true
        }
    };

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.getProfile();
    }

    functions = {
        getProfile: async () => {
            try {
                if (this.props.match.params.id) {
                    let response = await this.calls.getProptree(this.props.match.params.id);
                    this.setState({
                        user: response.data,
                        loading: false
                    });
                } else {
                    let values = window.location.pathname.replace("/proptree/", "");
                    let values_parsed = values.split("/");
                    if (values_parsed.length > 1) {
                        let response = await this.calls.getProfile(values_parsed[0], values_parsed[1], values_parsed[2]);
                        this.setState({
                            user: response.data,
                            loading: false
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        fallbackCopyTextToClipboard: (text) => {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                alert('Proptree copied to clipboard!');
            } catch (err) {
                console.error('Copy to clipboard failed');
            }
            document.body.removeChild(textArea);
        }
    };

    calls = {
        getProfile: (version, email, enterprise) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/proptree/profile?email=" + email + "&" + version + "=true" + "&enterprise=" + enterprise;
            return apiRegister.call(options, url);
        },
        getProptree: (id) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.proptree + "/getProptree/" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="min-h-full h-full">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="relative min-h-screen flex justify-center items-start">
                        <div className="w-full absolute h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <Disclosure as="nav" className="border-b border-gray-200 bg-white z-50 fixed left-0 right-0">
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <div className="flex h-16 justify-between">
                                        <div className="flex">
                                            <div className="flex flex-shrink-0 items-center">
                                                {
                                                    /*
                                                    this.state.user.enterprise &&
                                                    <img
                                                        className="h-12 w-auto hidden sm:block"
                                                        src={this.state.user.enterprise.logo}
                                                        alt="Your Company"
                                                    />
                                                */
                                                }
                                                {
                                                    this.state.loading &&
                                                    <div
                                                        className="h-12 w-12 hidden sm:block bg-gray-200 animate-pulse rounded-full"
                                                    />
                                                }
                                                {
                                                    this.state.loading &&
                                                    <div
                                                        className="h-12 w-12 block sm:hidden bg-gray-200 animate-pulse rounded-full"
                                                    />
                                                }
                                                {
                                                    !this.state.loading && this.state.user.enterprise && this.state.user.enterprise.favicon &&
                                                    <img
                                                        className="h-12 w-12 hidden sm:block cursor-pointer"
                                                        src={this.state.user.enterprise.favicon}
                                                        alt=""
                                                        onClick={() => {
                                                            //TODO: add link to agency page
                                                        }}
                                                    />
                                                }
                                                {
                                                    !this.state.loading && this.state.user.enterprise && this.state.user.enterprise.favicon &&
                                                    <img
                                                        className="h-12 w-12 block sm:hidden cursor-pointer"
                                                        src={this.state.user.enterprise.favicon}
                                                        alt=""
                                                        onClick={() => {
                                                            //TODO: add link to agency page
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            {/* Profile dropdown */}
                                            <div className="flex flex-row items-center">
                                                <div className="flex items-center">
                                                    <Menu as="div" className="ml-3 relative">
                                                        <div>
                                                            <a
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    let shareUrl = window.location.href + '?utm_share=agent_profile';

                                                                    // Check if navigator.share is supported
                                                                    if (navigator.share) {
                                                                        navigator.share({
                                                                            title: 'Share this Proptree',
                                                                            text: 'Check out this Proptree!',
                                                                            url: shareUrl
                                                                        }).then(() => {
                                                                            //console.log('Shared successfully');
                                                                        }).catch((error) => {
                                                                            //TODO: change state triggers refresh
                                                                            //this.setState({ linkCopied: true });
                                                                            //this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                        });
                                                                    } else if (navigator.clipboard && navigator.clipboard.writeText) {
                                                                        // Fallback to clipboard API if navigator.share is not supported
                                                                        navigator.clipboard.writeText(shareUrl).then(() => {
                                                                            this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                        }).catch((error) => {
                                                                            //TODO: change state triggers refresh
                                                                            //this.setState({ linkCopied: true });
                                                                            this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                        });
                                                                    } else {
                                                                        //TODO: change state triggers refresh
                                                                        //this.setState({ linkCopied: true });
                                                                        this.renders.fallbackCopyTextToClipboard(shareUrl);
                                                                    }
                                                                }}
                                                                className="h-10 hover:bg-gray-100 items-center justify-center text-sm font-semibold text-gray-900 px-4 rounded-md flex flex-row focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                            >
                                                                <div>Click</div>
                                                                <CursorClickIcon className="w-5 h-5 ml-2 mr-2"></CursorClickIcon>
                                                                <div>Share</div>
                                                            </a>
                                                            {
                                                                false && this.state.linkCopied &&
                                                                <div className="absolute top-0 -ml-40 mt-2 bg-white border border-gray-200 text-xxs text-green-500 px-2 py-1 rounded-md shadow-lg">
                                                                    Proptree copied to clipboard!
                                                                </div>
                                                            }
                                                        </div>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            false &&
                                            <div className="-mr-2 flex items-center sm:hidden">
                                                {/* Mobile menu button */}
                                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                    <span className="sr-only">Open main menu</span>
                                                    {open ? (
                                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </Disclosure.Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <Disclosure.Panel className="sm:hidden">
                                    <div className="space-y-1 pt-2 pb-3">
                                        {navigation.map((item) => (
                                            <Disclosure.Button
                                                onClick={() => {
                                                    this.props.history.push(item.link);
                                                }}
                                                key={item.name}
                                                as="div"
                                                className={classNames(
                                                    window.location.pathname === item.link
                                                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer'
                                                )}
                                                aria-current={window.location.pathname === item.link ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                    <div className="border-t border-gray-200 pt-4 pb-3">
                                        <div className="flex items-center px-4">
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={this.state.user.image} alt="" />
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium text-gray-800">{this.state.user.name}</div>
                                                <div className="text-sm font-medium text-gray-500">{this.state.user.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                }
                {
                    !this.state.loading &&
                    <div className="pt-16 min-h-full h-full">
                        <main>
                            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-full min-h-full pt-8">
                                <div className="h-full min-h-full">
                                    {
                                        (window.location.origin.indexOf('localhost') !== -1) &&
                                        <Route exact path={"/:id/proptree"}>
                                            <LinktreePage alias={this.props.match.params.id} user={this.state.user} history={this.props.history} />
                                        </Route>
                                    }
                                    {
                                        (window.location.origin.indexOf('proptr.') !== -1) &&
                                        <Route exact path={"/:id"}>
                                            <LinktreePage alias={this.props.match.params.id} user={this.state.user} history={this.props.history} />
                                        </Route>
                                    }
                                </div>
                            </div>
                        </main>
                    </div>
                }
            </div>
        )
    }
}

export default ProptreeMenu;
