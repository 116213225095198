import React, { Component, Fragment } from 'react';
import { XCircleIcon, XIcon, ChevronDownIcon, ChevronUpIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'

class ProptreeHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [{ open: false }, { open: false }]
        }
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    renders = {};

    calls = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{ backgroundColor: "#F3F3F1" }} className="absolute inset-0 overflow-auto">

                {/* HERO SECTION*/}
                <div className="p-4 pt-24 pb-12 background-pattern min-h-screen w-full flex flex-col items-center justify-center">
                    <div className="flex flex-col items-center justify-center">
                        <img className="w-24 sm:w-32 md:w-32 mb-5" src={require("../assets/images/Proptree_Logotype_RGB_Black.svg")} />
                        <div className="flex items-center justify-center flex-col">
                            <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl sm:max-w-130 text-center sm:text-2xl md:text-7xl">
                                Your <span className="underline">listings</span> in one synced link in bio.
                            </div>
                            <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="mt-6 sm:max-w-120 sm:mt-4 text-center font-medium">
                                Personal shortlinks to generate leads from your team's social profiles – always ON, always in sync with your CRM
                            </div>
                            <img className="w-120 mt-10 mb-4" src={require("../assets/images/PT_Mockup_dark_variant_A_v1.png")} />
                            <div className="flex flex-1 justify-center">
                                <div style={{ fontSize: "1.1rem", backgroundColor: "#1E2330" }} className="rounded-full text-white shadow-sm mt-10 inline-flex items-center justify-center h-16 px-12">
                                    Coming soon...
                                </div>
                            </div>
                            {
                                <div className="py-24 overflow-hidden">
                                    <div className="container px-4 mx-auto">
                                        <p style={{ color: "#1E2330" }} className="mb-10 text-gray-300 text-center font-medium text-xs">Empowered by the world’s smartest tech</p>
                                        <div className="container px-4 mx-auto">
                                            <div className="flex flex-wrap items-center justify-center -m-6">
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/alphabet-black.png")} className="w-24" />
                                                </div>
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/google-cloud-black.png")} className="w-32" />
                                                </div>
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/meta-black.png")} className="w-20" />
                                                </div>
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/microsoft-black.png")} className="w-24" />
                                                </div>
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/nvidia-black.png")} className="w-24" />
                                                </div>
                                                <div className="w-auto p-6">
                                                    <img src={require("../assets/images/openai-black.png")} className="w-24"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        false &&
                        <div style={{ zIndex: 9999 }} className="fixed left-0 right-0 top-0 sm:px-10 md:px-20 sm:py-12">
                            <div className="bg-white border sm:rounded-full w-full flex py-4 px-4 sm:px-12 sm:pr-4">
                                <div className="flex items-center pr-6">
                                    <div className="text-2xl font-medium">Proptree</div>
                                    <img className="w-8 ml-3" src={require("../assets/images/proptree_icon.svg")} />
                                </div>
                                <div className="flex pt-5 flex-1 overflow-hidden relative">

                                </div>
                                <div className="pl-6">
                                    <div className="flex cursor-pointer bg-gray-50 border items-center justify-center rounded-full h-16 px-12">
                                        <div style={{ color: "#1E2330" }} className="underline">Subscribe to news</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        false &&
                        <div className="flex pt-12 relative sm:pt-0 flex-col sm:flex-row h-full w-full">
                            <div className="flex flex-2 flex-col">
                                <div className="w-full h-42"></div>
                                <div className="w-full h-24"></div>
                                <div className="flex flex-1 items-center px-20 pl-32">
                                    <div>
                                        <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl md:max-w-130 text-center sm:text-left sm:text-4xl md:text-7xl">
                                            Your <span className="toolbox">listings</span> in one synced link.
                                        </div>
                                        <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="mt-6 md:max-w-120 sm:mt-4 text-center sm:text-left font-medium">
                                            Personal shortlinks to generate leads from your team's social profiles – always ON, always up to date with your CRM
                                        </div>
                                        <div className="flex flex-1 justify-center sm:justify-start">
                                            <div style={{ fontSize: "1.1rem", backgroundColor: "#1E2330" }} className="rounded-full text-white shadow-sm mt-10 inline-flex items-center justify-center h-16 px-12">
                                                Coming soon!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col sm:pr-48">
                                <div className="">
                                    <div className="flex sm:pt-48 flex-1 items-center justify-center">
                                        <div className="relative">
                                            <div style={{ width: "300px", height: "550px", borderRadius: "2rem", zIndex: 10, left: 0, top: 0 }} className="shadow-md border flex flex-col overflow-hidden bg-gray-50">
                                                <div className="h-16 bg-white border-b w-full pl-6 items-center flex">
                                                    <img className="w-16" src={require("../assets/images/pro_logo.png")} />
                                                </div>
                                                <div className="w-full mt-6">
                                                    <div className="flex w-full flex-col justify-center items-center">
                                                        <div className="rounded-full w-32 h-32 bg-white flex items-center justify-center overflow-hidden">
                                                            <img className="w-full" src={require("../assets/images/woman.png")} />
                                                        </div>
                                                        <div className="flex mt-4 items-center justify-center">
                                                            <div className="text-lg font-bold">@Nathalie Frick</div>
                                                            <img className="w-5 ml-1" src={require("../assets/images/Twitter_Verified_Badge.svg")} />
                                                        </div>
                                                        <div className="text-sm">Real Estate LLC</div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 flex-col relative w-full">
                                                    <div className="absolute left-0 right-0 top-0 bottom-0 p-8 pt-6">
                                                        <div className="bg-white border rounded-md w-full h-48"></div>
                                                        <div className="bg-white border rounded-md w-full mt-4 h-48"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ right: "-200px", top: "30px", zIndex: 0, backgroundColor: "#F3F3F1" }} className="rounded-full border shadow-md overflow-hidden flex items-center absolute h-64 w-64">
                                                <div className="flex">
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-10 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-24 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-16 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col relative">
                                                        <div style={{ top: "-30px", left: "-10px" }} className="absolute font-medium text-sm">Leads</div>
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-32 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-24 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-10 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-1"></div>
                                                        <div style={{ opacity: 0.7 }} className="w-4 h-16 mr-6 rounded-md bg-black"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ right: "240px", top: "310px", zIndex: 0 }} className="p-5 pb-3 bg-white border shadow-md flex flex-col overflow-hidden w-48 h-48 rounded-full absolute">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {/* LOGO SECTION*/}
                {
                    false &&
                    <div style={{ backgroundColor: "#1E2330" }} className="w-full border-t border-b h-48 relative" >
                        <div class="w-full absolute pt-12">
                            <div class="wrapper overflow-hidden">
                                <div class="marquee">
                                    <p>
                                        {
                                            [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item) => {
                                                return (
                                                    <p className="w-24 h-24 rounded-full bg-gray-50 mx-5"></p>
                                                )
                                            })
                                        }
                                    </p>
                                    <p>
                                        {
                                            [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item) => {
                                                return (
                                                    <p className="w-24 h-24 rounded-full bg-gray-50 mx-5"></p>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* FEATURE SECTION*/}
                {
                    false &&
                    <div className="w-full pt-24 pb-24">
                        <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl text-center flex-1 flex justify-center sm:text-4xl md:text-7xl">
                            <div className="md:max-w-140">The fast, friendly and powerful <span className="toolbox">link in bio</span> tool.</div>
                        </div>
                        <div className="sm:px-10 md:px-20 pt-16">
                            <div className="grid grid-cols-12 gap-16">
                                <div style={{ borderRadius: '50px' }} className="col-span-4 h-120 border bg-white flex flex-col">
                                    <div className="flex flex-1"></div>
                                    <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="p-8 text-center font-medium">
                                        <div>Seamlessly connect your Linktree with the tools you already use.</div>
                                    </div>
                                </div>
                                <div style={{ borderRadius: '50px' }} className="col-span-4 h-120 border bg-white flex flex-col">
                                    <div className="flex flex-1"></div>
                                    <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="p-8 text-center font-medium">
                                        <div>Customize your Linktree to match your brand. Make it feel like you.</div>
                                    </div>
                                </div>
                                <div style={{ borderRadius: '50px' }} className="col-span-4 h-120 border bg-white flex flex-col">
                                    <div className="flex flex-1"></div>
                                    <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="p-8 text-center font-medium">
                                        <div>Manage, update and schedule content with our quick, easy editor.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* REFERAL SECTION*/}
                {
                    false &&
                    <div className="w-full pt-24 pb-24 relative bg-white">
                        <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl text-center flex-1 flex justify-center sm:text-4xl md:text-7xl">
                            <div className="md:max-w-140">What do our <span className="toolbox">proptree agents</span> think</div>
                        </div>
                        <div className="flex mt-16 flex-1 items-center justify-center">
                            <div style={{ backgroundColor: "#F3F3F1", borderRadius: "50px", maxWidth: '900px' }} className="border w-full p-16 inline-flex flex-col">
                                <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl text-center flex-1 flex justify-center sm:text-4xl md:text-5xl">
                                    <div className="md:max-w-140">“I’d recommend Linktree to other creators! It’s very powerful for getting more eyes on your content.”</div>
                                </div>
                                <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="mt-6 text-center font-medium">
                                    <div>Catie T (Millennial Money Honey),</div>
                                    <div>Designer, Art Director and Financial Independence Educator</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 items-center justify-center mt-8 flex">
                            <div className="flex">
                                <div className="border cursor-pointer hover:bg-black hover:text-white rounded-md h-16 w-16 flex items-center justify-center">
                                    <ArrowLeftIcon className="w-5" />
                                </div>
                                <div className="w-4"></div>
                                <div className="border cursor-pointer hover:bg-black hover:text-white  rounded-md h-16 w-16 flex items-center justify-center">
                                    <ArrowRightIcon className="w-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* Q&A SECTION*/}
                {
                    false &&
                    <div style={{ backgroundColor: "#1E2330" }} className="w-full pt-24 pb-24">
                        <div style={{ color: "#F3F3F1", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl text-center flex-1 flex justify-center sm:text-4xl md:text-7xl">
                            <div className="md:max-w-140">Got questions</div>
                        </div>
                        <div className="pt-16 px-20 flex flex-col items-center">
                            {
                                this.state.questions.map((item) => {
                                    return (
                                        <div onClick={() => {
                                            item.open = !item.open;
                                            this.setState({
                                                questions: this.state.questions
                                            });
                                        }} style={{ borderRadius: "50px", maxWidth: '900px' }} className="bg-white mb-6 border w-full p-12 bg-opacity-25">
                                            <div style={{ color: "#F3F3F1" }} className="cursor-pointer">
                                                <div className="flex-1 flex">
                                                    <div className="flex flex-1 font-medium text-2xl">
                                                        Why do I need a Proptree?
                                                    </div>
                                                    <div>
                                                        {
                                                            !item.open &&
                                                            <ChevronDownIcon className="w-10" />
                                                        }
                                                        {
                                                            item.open &&
                                                            <ChevronUpIcon className="w-10" />
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    item.open &&
                                                    <div className="text-lg pt-12">
                                                        Linktree is a single link that you can use to connect audiences to everything you are, everything you do, and everything you care about. It enables you to share many links in one, so your followers, visitors and customers can find everything they’re looking for in one place.
                                                        <br />
                                                        <br />
                                                        You can add it to your bio link on social media platforms like Tiktok and Instagram, include it in your email signatures, and even display it as a QR code to get your offline audiences connected with you online. Linktree is for everyone – that’s why 50M+ creators already swear by it!
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/* FOOTER SECTION*/}
                {
                    false &&
                    <div style={{ backgroundColor: "#1E2330" }} className="w-full">
                        <div className="px-20">
                            <div style={{ borderTopLeftRadius: '50px', borderTopRightRadius: "50px" }} className="bg-white shadow-sm h-32"></div>
                        </div>
                    </div>
                }

            </div >
        )
    }
}

export default ProptreeHome;
