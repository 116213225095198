import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { ChartPieIcon, CheckCircleIcon, ChevronDownIcon, MailOpenIcon, MapIcon, MinusCircleIcon, PencilAltIcon, PhotographIcon, PlayIcon, PlusCircleIcon, RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import { versionRegister } from '../../services/versionRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateNewFeed from '../../components/createNewFeed';
import CreateDislayLink from '../../components/createDisplayLink';
import CheckFeed from '../../components/checkFeed';
import Pusher from 'pusher-js';

var pusherService = null;

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListFeeds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            meta: {},
            open: false,
            open_display: false,
            tab: { id: 'all', name: "All feeds", value: 'all', count: 0 },
            status: { id: 'enabled', name: "Enabled", value: 'enabled' },
            feeds: [],
            user: {},
            version: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            loading_feeds: false,
            sync_step: "first",
            steps: {
                "first": "1",
                "second": "2",
                "third": "3"
            },
            steps_title: {
                "first": "Checking for changes",
                "second": "Updating listings",
                "third": "Updating feeds"
            }
        }
    };

    componentDidMount() {

        pusherService = new Pusher('a4ee0d05afe391530eb1', {
            cluster: 'ap1'
        });

        this.functions.getUser();
        this.functions.getFeeds(true);
    }

    componentWillUnmount() {
        if (this.state.user.enterprise && this.state.user.enterprise.id) {
            pusherService.unsubscribe("agent_sync_" + this.state.user.enterprise);
        }
    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                });
                let version = versionRegister.get();
                await this.promisedSetState({ version: version ? version : {} });
                if (this.state.user.syncing) {
                    await this.promisedSetState({
                        loading_sync: true
                    });
                }
                this.functions.startGlobalListener();
            }
        },
        startGlobalListener: async () => {
            let self = this;
            if (self.state.user.enterprise && self.state.user.enterprise.id) {
                let channel = pusherService.subscribe("agent_sync_" + self.state.user.enterprise.id);
                self.setState({
                    allow_sync: true
                });
                channel.bind('syncEnterprise', function (response) {
                    console.log(response);
                    if ("syncing" in response) {
                        self.setState({
                            loading_sync: response.syncing
                        });
                    }
                    if ("step" in response) {
                        self.setState({
                            sync_step: response.step
                        });
                    }
                    if (response.finished) {
                        self.setState({
                            loading_sync: false,
                            sync_step: "first"
                        });
                        self.functions.getFeeds();
                    }
                });
            }
        },
        getFeeds: async (init) => {
            try {
                await this.promisedSetState({
                    loading: init,
                    loading_feeds: !init
                });
                let response = await this.calls.getFeeds();
                await this.promisedSetState({
                    total: response.meta.total,
                    feeds: response.data,
                    meta: response.meta,
                    loading: false,
                    loading_feeds: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        getFeed: async (feed) => {
            try {
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = true;
                        }
                        return item;
                    })
                });
                let response = await this.calls.getFeed(feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = false;
                            item.creatives = response.data.listings;
                        }
                        return item;
                    })
                });
            } catch (error) {
                await this.promisedSetState({
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == feed.id) {
                            item.loading_creatives = false;
                        }
                        return item;
                    })
                });
            }
        },
        removeFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeFeed(selected_feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                });
                this.functions.getFeeds();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        disableFeed: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.disableFeed(selected_feed.id);
                await this.promisedSetState({
                    remove: null,
                    remove_name: "",
                });
                this.functions.getFeeds();
            } catch (error) {
                console.log(error);
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        enableFeed: async (agent) => {
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.enableFeed(agent.id);
                this.functions.getFeeds();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == agent.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        downloadImage: async (item) => {
            try {
                const image = await fetch(item.download_url);
                const imageBlog = await image.blob();
                const imageURL = URL.createObjectURL(imageBlog);
                const link = document.createElement('a');
                link.href = imageURL;
                link.download = item.externalId;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            } catch (error) { }
            item.loading = false;
            this.setState({
                feeds: this.state.feeds
            });
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        },
        syncEnterprise: async (step) => {
            let self = this;
            if (self.state.allow_sync && !self.state.loading_sync) {
                try {
                    document.getElementById("table_container").scrollLeft = 0;
                } catch (error) { }
                await self.promisedSetState({ loading_sync: true });
                try {
                    await self.calls.syncEnterprise();
                } catch (error) {
                    pusherService.unsubscribe("agent_sync_" + self.state.user.enterprise.id);
                    await self.promisedSetState({ loading_sync: false, sync_error: true });
                }
            }
        }
    };

    calls = {
        getFeeds: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.status.value + "&version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        getFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light/" + id;
            return apiRegister.call(options, url);
        },
        removeFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/feed-light/" + id;
            return apiRegister.call(options, url);
        },
        disableFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/feed-light/disable/" + id;
            return apiRegister.call(options, url);
        },
        enableFeed: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/feed-light/enable/" + id;
            return apiRegister.call(options, url);
        },
        syncEnterprise: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/enterprise/syncEnterpriseFromAgentView";
            return apiRegister.call(options, url);
        }
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className={(this.props.admin ? "p-6 " : "sm:p-0 p-6 pt-0 ") + " grid grid-cols-12 h-full min-h-full bg-gray-50"}>

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading feeds ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={this.state.remove && this.state.remove.disabled ? "Delete Feed" : "Disable feed"}
                    description={"Are you sure you want to " + (this.state.remove && this.state.remove.disabled ? "delete" : "disable") + " this feed? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={this.state.remove && this.state.remove.disabled ? "delete" : "disable"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name"'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            if (this.state.remove.disabled) {
                                this.functions.removeFeed();
                            } else {
                                this.functions.disableFeed();
                            }
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*SYNC MODAL*/}
                <WarningModal
                    open={this.state.sync ? true : false}
                    title={"Sync datasource"}
                    decisionModal={true}
                    description={"Confirm to sync datasource. This may take a few minutes"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"start sync"}
                    showInput={false}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ sync: null })
                    }}
                    onSubmit={(value) => {
                        this.setState({ sync: false });
                        this.functions.syncEnterprise();
                    }}
                />

                {/*ERROR MODAL*/}
                <WarningModal
                    open={this.state.sync_error ? true : false}
                    softWarning={true}
                    title={"Sync cool down"}
                    description={"Please wait 10 min"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            sync_error: null
                        })
                    }}
                />

                {/*CHECK FEED*/}
                <CheckFeed
                    version={this.state.version}
                    admin={this.props.admin}
                    enterprise={this.state.user.enterprise}
                    feed={this.state.check_feed}
                    open={this.state.check_feed ? true : false}
                    dontShowSubmitButton={true}
                    toggle={() => {
                        this.setState({
                            check_feed: false
                        })
                    }}
                    onCreate={(feed) => {

                    }}
                    onUpdate={(feed) => {

                    }}
                />

                {/*CREATE FEED*/}
                <CreateNewFeed
                    version={this.state.version}
                    admin={this.props.admin}
                    enterprise={this.state.user.enterprise}
                    feed={this.state.selected}
                    open={this.state.open}
                    //dontShowSubmitButton={this.state.selected}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onCreate={(feed) => {
                        this.functions.getFeeds();
                    }}
                    onUpdate={(feed) => {
                        this.functions.getFeeds();
                    }}
                />

                {/*CREATE DISPLAY LINK*/}
                <CreateDislayLink
                    version={this.state.version}
                    admin={this.props.admin}
                    enterprise={this.state.user.enterprise}
                    feed={this.state.selected}
                    open={this.state.open_display}
                    dontShowSubmitButton={true}
                    toggle={() => {
                        this.setState({
                            open_display: !this.state.open_display
                        })
                    }}
                    onCreate={(feed) => {
                        //this.functions.getFeeds();
                    }}
                    onUpdate={(feed) => {
                        //this.functions.getFeeds();
                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.tab}
                                options={[
                                    // { id: 'all', name: "All feeds", value: 'all', count: this.state.meta.all },
                                    { id: 'all', name: "All feeds", value: 'all', count: this.state.meta.total },
                                    { id: 'facebook', name: "Meta", value: 'facebook', count: this.state.meta.facebook },
                                    ...(this.state.user.enterprise &&
                                        this.state.user.enterprise.managerUIproptree &&
                                        this.state.user.enterprise.managerUIproptree.value === 'enabled' || this.props.admin
                                        ? [{ id: 'proptree', name: "Proptree", value: 'proptree', count: this.state.meta.proptree }]
                                        : []),
                                    // { id: 'google', name: "Google", value: 'google', count: this.state.meta.google },
                                    // { id: 'snapchat', name: "Snapchat", value: 'snapchat', count: this.state.meta.snapchat },
                                    // { id: 'tiktok', name: "Tik Tok", value: 'tiktok', count: this.state.meta.tiktok }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getFeeds();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*STATUS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.status}
                                options={[
                                    { id: 'enabled', name: "Enabled", value: 'enabled', count: this.state.meta.enabled },
                                    { id: 'disabled', name: "Disabled", value: 'disabled', count: this.state.meta.disabled }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        status: option,
                                        page: 1
                                    });
                                    this.functions.getFeeds();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-4"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt', adsOnly: false },
                                    { id: 2, name: "Listings", value: 'listings', adsOnly: false }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getFeeds();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                locked={this.state.loading_sync}
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getFeeds();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LISTINGS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className={(this.state.loading_sync ? "overflow-hidden" : "") + " bg-white shadow-lg border rounded-lg"}>
                                    <div className="flex p-4 lg:px-6 border-b border-gray-100 items-center">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        {
                                            this.state.status.value == "enabled" &&
                                            this.state.user.enterprise && this.state.user.enterprise.limitFeedsAmount && this.state.user.enterprise.limitFeedsAmount.value <= this.state.total &&
                                            !this.state.loading_sync &&
                                            <div className=" rounded-md text-red-600 font-medium text-sm px-4 py-2">
                                                Upgrade or disable feed {this.state.user.enterprise && this.state.user.enterprise.limitFeedsAmount && this.state.user.enterprise.limitFeedsAmount.value ? "[" + this.state.user.enterprise.limitFeedsAmount.value + "/" + this.state.total + " created]" : "[-/- created]"}
                                            </div>
                                        }
                                        {
                                            this.state.user.enterprise && this.state.user.enterprise.managerUIcreateFeeds && this.state.user.enterprise.managerUIcreateFeeds.value === 'enabled' &&
                                            this.state.user.access && this.state.user.access.id === 1 &&
                                            this.state.allow_sync &&
                                            !this.state.loading_sync &&
                                            <div onClick={() => {
                                                this.setState({
                                                    sync: true
                                                })
                                            }} className={(this.state.loading_sync ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " text-sm font-medium ml-4 px-4 h-10 rounded-md bg-purple-100 border-purple-500 items-center flex flex-row text-purple-500 hover:text-purple-700"}>
                                                <RefreshIcon className="w-5 h-5 mr-2" />
                                                Sync
                                            </div>
                                        }
                                        {
                                            this.state.user.enterprise && this.state.user.enterprise.limitFeedsAmount && this.state.user.enterprise.limitFeedsAmount.value > this.state.total &&
                                            this.state.user.enterprise && this.state.user.enterprise.managerUIcreateFeeds && this.state.user.enterprise.managerUIcreateFeeds.value === 'enabled' &&
                                            this.state.user.access && this.state.user.access.id === 1 &&
                                            !this.state.loading_sync &&
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className="text-sm font-medium cursor-pointer h-10  px-4  ml-4 rounded-md bg-purple-100 border-purple-500 items-center flex flex-row text-purple-500 hover:text-purple-700">
                                                <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                Create {this.state.user.enterprise && this.state.user.enterprise.limitFeedsAmount && this.state.user.enterprise.limitFeedsAmount.value ? "[" + (this.state.user.enterprise.limitFeedsAmount.value - this.state.total) + " left]" : "[- left]"}
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.feeds.length < 1 &&
                                        <div className="h-110 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_feeds &&
                                                <div className="font-medium text-sm">No Feeds available</div>
                                            }
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div id={"table_container"} className={(this.state.loading_sync ? "" : "overflow-x-scroll ") + "relative min-h-110"}>
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listings</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Channel</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Theme</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Type</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Language</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Currency</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Property</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.feeds.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        <td className="text-left pl-6">
                                                                            <div className="flex flex-row">
                                                                                <Menu as="div" className="relative inline-block text-left">
                                                                                    <div>
                                                                                        <Menu.Button className="inline-flex relative items-center hover:bg-gray-50 justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                            {
                                                                                                !agent.loading &&
                                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            }
                                                                                            {
                                                                                                agent.loading &&
                                                                                                <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                        class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                        </Menu.Button>
                                                                                    </div>
                                                                                    <Transition
                                                                                        as={Fragment}
                                                                                        enter="transition ease-out duration-100"
                                                                                        enterFrom="transform opacity-0 scale-95"
                                                                                        enterTo="transform opacity-100 scale-100"
                                                                                        leave="transition ease-in duration-75"
                                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                                        leaveTo="transform opacity-0 scale-95"
                                                                                    >
                                                                                        <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg" style={{ zIndex: "9999" }}>
                                                                                            <div className="py-1">
                                                                                                {
                                                                                                    [
                                                                                                        { name: "Edit", value: "edit", icon: PencilAltIcon },
                                                                                                        { name: "Leads", value: "leads", icon: MailOpenIcon },
                                                                                                        { name: "Report", value: "report", icon: ChartPieIcon },
                                                                                                        //{ name: "Display link", value: "display", icon: PlayIcon },
                                                                                                        { name: "Details", value: "feed", icon: MapIcon },
                                                                                                        { name: "Disable", value: "disable", icon: MinusCircleIcon },
                                                                                                        { name: "Enable", value: "enable", icon: CheckCircleIcon },
                                                                                                        { name: "Remove", value: "remove", icon: TrashIcon },
                                                                                                    ].filter((item) => {
                                                                                                        if (item.value == "feed" || item.value == "display" || item.value == "edit") {
                                                                                                            return this.state.status.value == "enabled"
                                                                                                        } else if (item.value == "report") {
                                                                                                            return this.state.status.value == "enabled" && this.state.user.enterprise && this.state.user.enterprise.managerUIreport && this.state.user.enterprise.managerUIreport.value === 'enabled';
                                                                                                        } else if (item.value == "disable") {
                                                                                                            return !agent.disabled
                                                                                                        } else if (item.value == "remove" || item.value == "enable") {
                                                                                                            return agent.disabled
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                    }).filter((item) => {
                                                                                                        if (agent.channel && agent.channel.value == "proptree") {
                                                                                                            return item.value == "disable" || item.value == "enable" || item.value == "remove"
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                    }).map((item) => {
                                                                                                        return (
                                                                                                            <Menu.Item>
                                                                                                                {({ active }) => (
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            if (item.value == "edit") {
                                                                                                                                this.setState({
                                                                                                                                    open: true,
                                                                                                                                    selected: agent
                                                                                                                                })
                                                                                                                            } else if (item.value == "feed") {
                                                                                                                                this.setState({
                                                                                                                                    check_feed: agent
                                                                                                                                });
                                                                                                                            } else if (item.value == "report") {
                                                                                                                                this.props.history.push("report?feed=" + agent.id);
                                                                                                                            } else if (item.value == "display") {
                                                                                                                                this.setState({
                                                                                                                                    open_display: true,
                                                                                                                                    selected: agent
                                                                                                                                })
                                                                                                                            } else if (item.value == "disable" || item.value == "remove") {
                                                                                                                                this.setState({
                                                                                                                                    remove: agent
                                                                                                                                })
                                                                                                                            } else if (item.value == "enable") {
                                                                                                                                this.functions.enableFeed(agent)
                                                                                                                            } else if (item.value == "leads") {
                                                                                                                                this.props.history.push("/manager/leads?campaigns=" + agent.campaigns);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        className={classNames(
                                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                            'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                            (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <div className="flex flex-1">{item.name}</div>
                                                                                                                        <item.icon className="w-5" />
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Menu.Item>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </Menu.Items>
                                                                                    </Transition>
                                                                                </Menu>
                                                                                {
                                                                                    this.state.status.value == "enabled" &&
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            if (!(agent.channel && agent.channel.value == "proptree")) {
                                                                                                agent.open = !agent.open;
                                                                                                if (agent.open) {
                                                                                                    this.functions.getFeed(agent);
                                                                                                }
                                                                                                this.setState({
                                                                                                    feeds: this.state.feeds
                                                                                                });
                                                                                            }
                                                                                        }} className="inline-flex relative overflow-hidden cursor-pointer hover:bg-gray-50 items-center ml-2 justify-center rounded-md bg-white border px-4 h-10 text-sm font-medium text-gray-900">
                                                                                        {
                                                                                            !agent.open &&
                                                                                            <div>Creatives</div>
                                                                                        }
                                                                                        {
                                                                                            agent.open &&
                                                                                            <div>Close</div>
                                                                                        }
                                                                                        {
                                                                                            agent.channel && agent.channel.value == "proptree" &&
                                                                                            <div className="left-0 right-0 bottom-0 top-0 absolute bg-white opacity-75 cursor-not-allowed">

                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.props.admin &&
                                                                            <td className="flex items-center px-6 py-4">
                                                                                <div className="h-10 min-w-10 rounded-full overflow-hidden flex items-center justify-center border">
                                                                                    <img className="w-10" src={agent.enterprise ? agent.enterprise.logo : ""} alt="" />
                                                                                </div>
                                                                                <div className="ml-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.enterprise ? agent.enterprise.name : ""}</div>
                                                                                    <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">

                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.props.admin &&
                                                                            <td className="flex items-center px-6 py-4">
                                                                                {
                                                                                    agent.listings.filter((item, index) => { return index < 3 }).map((item, index) => {
                                                                                        return (
                                                                                            <div className={(index !== 0 ? "-ml-4" : "") + " flex-shrink-0 overflow-hidden rounded-full h-10 w-10 border border-white"}>
                                                                                                <img className="w-full h-full" src={item.imagesInternal[0]} alt="" />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {
                                                                                    agent.listings.length > 3 &&
                                                                                    <div className={(agent.listings.filter((item, index) => { return index < 4 }).length > 0 ? "-ml-4" : "") + " flex-shrink-0 flex justify-center border border-gray-500 items-center h-10 w-10 rounded-full bg-gray-100"}>
                                                                                        <div className="text-xxs font-medium text-gray-500">
                                                                                            {agent.rows - 3}+
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className={
                                                                                //(+agent.listings_length < 100 ? "text-red-500" : +agent.listings_length < 50 ? "text-yellow-500" : "text-green-500") + " text-sm whitespace-no-wrap"
                                                                                "text-sm whitespace-no-wrap"
                                                                            }>
                                                                                {+agent.listings_length > 0 ? +agent.listings_length : "-"}
                                                                                {
                                                                                    +agent.listings_length > 0 &&
                                                                                    +agent.listings_length < 50 &&
                                                                                    <span className="text-red-600 pl-1">(low)</span>
                                                                                }
                                                                                {
                                                                                    +agent.listings_length > 0 &&
                                                                                    +agent.listings_length >= 50 && +agent.listings_length < 100 &&
                                                                                    <span className="text-yellow-600 pl-1">(medium)</span>
                                                                                }
                                                                                {
                                                                                    +agent.listings_length > 0 &&
                                                                                    +agent.listings_length >= 100 &&
                                                                                    <span className="text-green-600 pl-1">(good)</span>
                                                                                }
                                                                            </div>
                                                                            <div className="text-sm text-gray-500">Items</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.average_price ? agent.average_price : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Median price</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.channel ? agent.channel.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Channel</div>
                                                                        </td>
                                                                        {
                                                                            !this.props.admin &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name ? agent.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Name</div>
                                                                            </td>
                                                                        }
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.theme ? agent.theme.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Theme</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.language ? agent.language.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Language</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.currency ? agent.currency.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Currency</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.dial_code ? agent.dial_code.value : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Country code</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.sizeUnit ? agent.sizeUnit.value : 'sqft'}</div>
                                                                            <div className="text-sm text-gray-500">Size unit</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.type ? agent.type.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Type</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.property ? agent.property.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Plan</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? "Projects" : "Units"}</div>
                                                                            <div className="text-sm text-gray-500">Category</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.traffic ? agent.traffic.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Ad</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.qualification ? agent.qualification.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Traffic</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.form ? agent.form.name : "-"}</div>
                                                                            <div className="text-sm text-gray-500">Form</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={agent.email} className={(agent.open ? "h-100 border-b" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative z-0"}>
                                                                        {
                                                                            agent.open &&
                                                                            <div className="absolute w-full bg-gray-50 h-100 flex justify-left items-center">
                                                                                {
                                                                                    agent.loading_creatives &&
                                                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-10 h-10 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                                <div className="px-6 flex flex-row overflow-x-auto">
                                                                                    {
                                                                                        Array.isArray(agent.creatives) &&
                                                                                        agent.creatives.filter((item, index) => {
                                                                                            if (!agent.limit) {
                                                                                                return index < 10;
                                                                                            } else {
                                                                                                return index < agent.limit;
                                                                                            }
                                                                                        }).map((item) => {
                                                                                            return (
                                                                                                <div className="text-center">
                                                                                                    <div style={{ width: '250px', height: "250px" }} className="mr-6 bg-white relative">
                                                                                                        <div className="absolute w-full h-full z-10">
                                                                                                            <div className="w-full h-full relative">
                                                                                                                <iframe src={item.url + "&legacy=false"} width="250" height="250" frameborder="0" scrolling="no" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="absolute top-0 bottom-0 bg-white left-0 z-5 right-0 flex justify-center items-center ">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={(item.download_url ? "cursor-pointer" : " cursor-not-allowed opacity-25") + " mt-4 -ml-6 overflow-hidden relative inline-flex items-center hover:bg-gray-50 border px-4 h-10 text-sm font-medium rounded-md  bg-white "}
                                                                                                        onClick={() => {
                                                                                                            item.loading = true;
                                                                                                            this.setState({
                                                                                                                feeds: this.state.feeds
                                                                                                            });
                                                                                                            this.functions.downloadImage(item);
                                                                                                        }} >
                                                                                                        Download
                                                                                                        {
                                                                                                            item.loading &&
                                                                                                            <div className="absolute top-0 bottom-0 left-0 right-0 flex bg-white bg-opacity-75 justify-center items-center ">
                                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        Array.isArray(agent.creatives) &&
                                                                                        (agent.creatives.length > (agent.limit ? agent.limit : 10)) &&
                                                                                        <div style={{ width: '100px', height: "250px" }} className="flex justify-center items-center">
                                                                                            <div
                                                                                                className={"cursor-pointer whitespace-no-wrap mt-4 relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md text-purple-500  hover:text-purple-700"}
                                                                                                onClick={() => {
                                                                                                    if (!agent.limit) {
                                                                                                        agent.limit = 20;
                                                                                                    } else {
                                                                                                        agent.limit += 10;
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        feeds: this.state.feeds
                                                                                                    })
                                                                                                }} >Load more</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </tr>
                                                                </Fragment>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_feeds &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            {
                                                !this.state.loading_feeds && this.state.loading_sync &&
                                                <div className="absolute top-0 bottom-0 min-w-full flex flex-col justify-center items-center bg-white bg-opacity-75 w-full">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 border-solid rounded-full animate-spin"></div>
                                                    <div className="text-sm font-medium mt-4">Step {this.state.steps[this.state.sync_step]} of 3 - {this.state.steps_title[this.state.sync_step]}</div>
                                                    <div className="text-xs font-light mt-1">This may take a few of minutes</div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.feeds.length > 0 &&
                                        <div className="rounded-b-lg relative">

                                            {
                                                this.state.loading_sync &&
                                                <div className="absolute z-10 top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">

                                                </div>
                                            }

                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                {
                                                    this.state.feeds.length > 0 &&
                                                    <div className="hidden sm:block">
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                            <span className="font-medium">{this.state.total}</span> results
                                                        </p>
                                                    </div>
                                                }
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getFeeds();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getFeeds();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getFeeds();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div >
        )
    }
}

export default ListFeeds;
