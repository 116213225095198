import React, { Component, Fragment } from 'react';

class Proptree404 extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    renders = {};

    calls = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{ backgroundColor: "#F3F3F1" }} className="fixed left-0 right-0 top-0 bottom-0">

                {/* 404*/}
                <div className="background-pattern h-full w-full flex items-center justify-center">
                    <div className="flex items-center justify-center flex-col">
                        <img className="w-24 mb-2" src={require("../assets/images/proptree_icon.svg")} />
                        <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="mb-8">Proptr.ee</div>
                        <div className="flex items-center justify-center flex-col">
                            <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl sm:max-w-130 text-center sm:text-4xl md:text-7xl">
                                This page doesn't exist
                            </div>
                            <div style={{ color: "#1E2330", fontSize: "1.1rem", letterSpacing: "-0.02em", lineHeight: "1.5" }} className="mt-6 sm:max-w-120 sm:mt-4 text-center font-medium">
                                404
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

export default Proptree404;
