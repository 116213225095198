import React, { Component } from 'react';
import SuccessModal from '../components/successModal';
import { apiRegister } from "../services/apiRegister";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            number: "",
            loading: false,
            success: false
        }
    };

    functions = {
        forgotPassword: async () => {
            try {
                await this.promisedSetState({ loading: true });
                await this.calls.forgotPassword();
                //await this.promisedSetState({ loading: false, success: true });
            } catch (error) {
                await this.promisedSetState({ loading: false, error: true });
            }
        }
    };

    calls = {
        forgotPassword: (id) => {
            let options = apiRegister.options(null, 'POST', { number: this.state.number });
            let url = apiRegister.url.api + "/user/forgotPassword";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                <SuccessModal
                    open={this.state.success}
                    title={"Success"}
                    text={"You will recieve a reset link soon"}
                    button={"close"}
                    onClose={() => {
                        this.setState({
                            success: false
                        });
                        this.props.history.push("/login");
                    }}
                />

                <div style={{ height: "100vh" }} className="min-h-full flex flex-col justify-center py-12 px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md items-center">
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="h-8 w-auto"
                                src={require("../assets/images/adbooster_black.png")}
                                alt="Workflow"
                            />
                        </div>
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Forgot your password ? </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Please type your whatsapp below
                        </p>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white border py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        WhatsApp number (include country code)
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={(event) => {
                                                this.setState({
                                                    number: event.target.value
                                                })
                                            }}
                                            value={this.state.number}
                                            autoComplete="email"
                                            required
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            if (!this.state.loading) {
                                                this.functions.forgotPassword();
                                            }
                                        }}
                                        className="w-full relative flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {
                                            this.state.loading &&
                                            <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ForgotPassword;
