import React, { Component, Fragment } from 'react';
import { PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import CreateUser from '../components/createUser';
import WarningModal from '../components/warningModal';
import { RefreshIcon, PlusCircleIcon, TrashIcon, PhoneIcon, PencilIcon, ArchiveIcon, ChevronDownIcon, CheckIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { versionRegister } from '../services/versionRegister';
import SingleSelection from '../components/singleSelection';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListAgents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_enterprise: true,
            open: false,
            tab: 2,
            users: [],
            user: {},
            version: {},
            enterprises: [],
            enterprise: { id: 0, name: "Select" },
            status: { id: 0, name: "Show all", value: "all" },
            sort: 'name',
            order: 'ascending',
            page: 1,
            page_count: 100,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
            this.functions.getEnterprise();
        },
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("enterprise=") !== -1) {
                    let value = queries[i].replace("enterprise=", "");
                    let enterprise = null;
                    this.state.enterprises.map((item) => {
                        if (item.id == value) {
                            enterprise = item;
                        }
                    });
                    await this.promisedSetState({
                        enterprise: enterprise ? enterprise : this.state.enterprise
                    });
                    if (this.state.enterprise.id !== 0) {
                        this.functions.getUsers();
                    }
                }
            }
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                await this.promisedSetState({
                    enterprises: response.data,
                    loading: false
                });
                this.functions.query();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false
                })
            }
        },
        getUsers: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getUsers();
                this.setState({
                    users: response.data,
                    total: response.meta.total ? response.meta.total : 0,
                    loading_users: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false
                })
            }
        },
        removeUser: async () => {
            let selected_user = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == selected_user.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeUser(selected_user.id);
                await this.promisedSetState({
                    users: this.state.users.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    users: this.state.users.map((item) => {
                        if (item.id == selected_user.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        updateAccess: async (user) => {
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == user.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.updateUser({ id: user.id, updated: { id: 3, name: "Accepted" } });
                await this.promisedSetState({
                    users: this.state.users.map((item) => {
                        if (item.id == user.id) {
                            item.loading = false;
                            item.updated = response.data.updated;
                        }
                        return item;
                    })
                });
            } catch (error) { }
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == user.id) {
                        item.loading = false;
                    }
                    return item;
                })
            });
        },
        createLoginLink: async (user) => {
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == user.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.createLoginLink(user.id);
                if (response.data.token) {
                    if (window.location.hostname === 'localhost') {
                        window.open("http://localhost:5001/login/" + response.data.token);
                    } else if (window.location.hostname === 'app.adbooster.se') {
                        window.open("https://app.adbooster.se/login/" + response.data.token);
                    } else if (window.location.hostname === 'app.adbooster.ai') {
                        window.open("https://app.adbooster.ai/login/" + response.data.token);
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == user.id) {
                        item.loading = false;
                    }
                    return item;
                })
            });
        },
        createProptreeAgent: async (agent) => {
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.createProptreeAgent({ id: agent.id });
                this.state.users = this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.proptree = response.data;
                    }
                    return item;
                });
                await this.promisedSetState({
                    users: this.state.users
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                users: this.state.users.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
    };

    calls = {
        getUsers: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/user/agents?enterprise=" + this.state.enterprise.id + "&page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order + "&sortBy=" + this.state.sort + "&status=" + this.state.status.id;
            return apiRegister.call(options, url);
        },
        getEnterprise: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/enterprise/list?version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        removeUser: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/user/remove/" + id;
            return apiRegister.call(options, url);
        },
        updateUser: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/user/update/" + data.id;
            return apiRegister.call(options, url);
        },
        createLoginLink: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/user/createLoginLink?id=" + id;
            return apiRegister.call(options, url);
        },
        createProptreeAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptreeAgent";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        users: () => {
            let users = this.state.users;
            if (typeof this.state.search == "string" && this.state.search !== "") {
                return users.filter((str) => {
                    return typeof str.email == "string" && str.email.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0;
                })
            } else {
                return users;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete User"}
                    description={"Are you sure you want to delete this user? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeUser();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-gray-100 border-gray-500">
                                        <div>
                                            <UsersIcon className="h-8 w-8 text-gray-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Agents</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*AGENT HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 2 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                        <div>
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.logo &&
                                                <img
                                                    className="w-full"
                                                    src={this.state.user.enterprise.logo}
                                                    alt=""
                                                />
                                            }
                                            {
                                                (!this.state.user.enterprise || (this.state.user.enterprise && !this.state.user.enterprise.logo)) &&
                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Agents</p>
                                        <p className="text-sm font-medium text-gray-600">{this.state.user.enterprise.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*LIST USERS*/}
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">

                                    <div className="flex items-center p-6 border-b">
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Manage agents</h3>
                                            <p className="mt-1 mr-5 text-sm text-gray-500">Contact your success manager if any questions.</p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (this.state.enterprise.id !== 0) {
                                                    this.setState({
                                                        open: true,
                                                        selected: null
                                                    })
                                                }
                                            }}
                                            type="button"
                                            className={(this.state.enterprise.id == 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700") + " inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Create new
                                        </button>
                                    </div>

                                    {/*FILTER USERS*/}
                                    <div className="grid sm:grid-cols-6 pb-6 gap-6 px-4 sm:px-6 pt-4">

                                        <div className={"sm:col-span-2 col-gap-6"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                Enterprise
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    noLimit={true}
                                                    loader={this.state.loading_enterprise}
                                                    select={(option) => {
                                                        this.setState({
                                                            enterprise: option
                                                        }, () => {
                                                            this.functions.getUsers();
                                                        });
                                                    }}
                                                    disabled={this.state.loading_users}
                                                    selected={this.state.enterprise}
                                                    options={this.state.enterprises}
                                                />
                                            </div>
                                        </div>
                                        <div className={"sm:col-span-2 col-gap-6"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                Change
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    select={(option) => {
                                                        this.setState({
                                                            status: option
                                                        }, () => {
                                                            if (this.state.enterprise.id !== 0) {
                                                                this.functions.getUsers();
                                                            }
                                                        });
                                                    }}
                                                    disabled={this.state.loading_users}
                                                    selected={this.state.status}
                                                    options={[
                                                        { id: 0, name: "Show all", value: "all" },
                                                        { id: 1, name: "New", value: "new" },
                                                        { id: 2, name: "Updated", value: "updated" },
                                                        { id: 3, name: "Accepted", value: "accepted" },
                                                        { id: 4, name: "Auto-updated", value: "auto-updated" },
                                                        { id: 5, name: "Auto-created", value: "auto-created" }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className={"sm:col-span-2 col-gap-6"}>
                                            <div>
                                                <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                    Search email
                                                </label>
                                                <input
                                                    value={this.state.search}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            search: event.target.value
                                                        })
                                                    }}
                                                    type="text"
                                                    name="contact-email"
                                                    id="contact-email"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !this.state.loading_users &&
                                        this.state.users.length > 0 &&
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-0 lg:-mx-0">
                                                <div className="py-2 align-middle inline-block min-w-full sm:px-0 lg:px-0">
                                                    <div className="border-t border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-100">
                                                                <tr>
                                                                    <th scope="col" className="relative border-r px-6 py-3">
                                                                        <span className="sr-only">Actions</span>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Status
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Role
                                                                    </th>
                                                                    <th
                                                                        style={{ minWidth: "200px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                sort: "name",
                                                                                page: 1,
                                                                                order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                            }, () => {
                                                                                this.functions.getUsers();
                                                                            })
                                                                        }}
                                                                        scope="col"
                                                                        className="px-6 py-6 border-r cursor-pointer hover:text-gray-600 text-left text-xs font-medium whitespace-no-wrap text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        <div className={(this.state.sort == "name" ? "text-gray-900" : "") + " flex flex-row"}>
                                                                            <div className="mr-4"> Information</div>
                                                                            {

                                                                                this.state.order == 'ascending' &&
                                                                                <SortAscendingIcon className={(this.state.sort == "name" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                            {

                                                                                this.state.order == 'descending' &&
                                                                                <SortDescendingIcon className={(this.state.sort == "name" ? "text-gray-900" : "opacity-0") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Listings
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Change
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Enterprise
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Access
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 border-r py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Phone
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {this.renders.users().map((person) => (
                                                                    <tr key={person.email}>
                                                                        <td style={{ width: "100px" }} className="px-6 border-r py-4 whitespace-nowrap text-left text-sm font-medium">
                                                                            <div className="text-indigo-600 flex hover:text-indigo-900 cursor-pointer">
                                                                                {
                                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                                        <div>
                                                                                            <Menu.Button className="inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                                                Options
                                                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                                {
                                                                                                    person.loading &&
                                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-indigo-600 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                <div className="py-1">
                                                                                                    {
                                                                                                        (person.updated && person.updated.id !== 3) &&
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        this.functions.updateAccess(person);
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-purple-500' : 'text-purple-500',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Accept update
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        selected: person
                                                                                                                    }, () => {
                                                                                                                        this.setState({
                                                                                                                            open: true
                                                                                                                        })
                                                                                                                    })
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Edit
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                    {
                                                                                                        (!person.access || (person.access && person.access.id === 1)) &&
                                                                                                        this.state.user.level && this.state.user.level.id == 1 &&
                                                                                                        this.state.tab == 2 &&
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        this.functions.createLoginLink(person);
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Login as Manager
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    {
                                                                                                        (!person.access || (person.access && person.access.id === 2)) &&
                                                                                                        this.state.user.level && this.state.user.level.id == 1 &&
                                                                                                        this.state.tab == 2 &&
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        /*
                                                                                                                        if (window.location.hostname === 'localhost') {
                                                                                                                            window.open("http://localhost:5001/login/" + person.id);
                                                                                                                        } else if (window.location.hostname === 'app.adbooster.se') {
                                                                                                                            window.open("https://app.adbooster.se/login/" + person.id);
                                                                                                                        } else if (window.location.hostname === 'app.adbooster.ai') {
                                                                                                                            window.open("https://app.adbooster.ai/login/" + person.id);
                                                                                                                        }
                                                                                                                        */
                                                                                                                        this.functions.createLoginLink(person);
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Login as Agent
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    {
                                                                                                        false &&
                                                                                                        (!person.access || (person.access && person.access.id === 2)) &&
                                                                                                        this.state.user.level && this.state.user.level.id == 1 &&
                                                                                                        this.state.tab == 2 &&
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (window.location.hostname === 'localhost') {
                                                                                                                            if (person.enterprise.version && person.enterprise.version.id == 1) {
                                                                                                                                window.open("http://localhost:5001/proptree/live/" + person.email + "/" + person.enterprise.id);
                                                                                                                            } else {
                                                                                                                                window.open("http://localhost:5001/proptree/development/" + person.email + "/" + person.enterprise.id);
                                                                                                                            }
                                                                                                                        } else if (window.location.hostname === 'app.adbooster.se' || window.location.hostname === 'app.adbooster.ai') {
                                                                                                                            if (person.enterprise.version && person.enterprise.version.id == 1) {
                                                                                                                                window.open("https://" + window.location.hostname + "/proptree/live/" + person.email + "/" + person.enterprise.id);
                                                                                                                            } else {
                                                                                                                                window.open("https://" + window.location.hostname + "/proptree/development/" + person.email + "/" + person.enterprise.id);
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={classNames(
                                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                        'block px-4 py-2 text-sm relative'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    Proptree
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        remove: person
                                                                                                                    })
                                                                                                                }}
                                                                                                                className={classNames(
                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                    'block px-4 py-2 text-sm relative'
                                                                                                                )}
                                                                                                            >
                                                                                                                Delete
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                }
                                                                                {
                                                                                    //(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                    //(this.state.user.enterprise && this.state.user.enterprise.managerUIproptree && this.state.user.enterprise.managerUIproptree.value === 'enabled') &&
                                                                                    (!person.access || (person.access && person.access.id === 2)) &&
                                                                                    this.state.user.level && this.state.user.level.id == 1 &&
                                                                                    this.state.tab == 2 &&
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            if (!(person.proptree && person.proptree.id)) {
                                                                                                if (!person.loading_proptree) {
                                                                                                    this.functions.createProptreeAgent(person);
                                                                                                }
                                                                                            } else {
                                                                                                if (window.location.origin.indexOf('localhost') !== -1) {
                                                                                                    window.open("http://localhost:5001/" + person.proptree.shortId + "/proptree", "_blank");
                                                                                                } else {
                                                                                                    window.open("https://proptr.ee/" + person.proptree.shortId + "/", "_blank");
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        className={"cursor-pointer border ml-4 relative inline-flex items-center w-32 overflow-hidden justify-center py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                    >
                                                                                        {person.proptree && person.proptree.id ? <span className="mr-1">Open</span> : <span className="mr-1">Create</span>} Proptree
                                                                                        {
                                                                                            person.loading_proptree &&
                                                                                            <div className="top-0 bottom-0 left-0 right-0 flex absolute justify-center items-center bg-white bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6  border-r py-4 whitespace-nowrap">
                                                                            <span className={(person.status && person.status.id == 1 ? "border-green-500 bg-green-100 text-green-500" : "border-red-500 bg-red-100 text-red-500") + " inline-flex items-center border px-4 py-1 rounded-md text-sm font-medium "}>
                                                                                {person.status.name}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-6  border-r py-4 whitespace-nowrap">
                                                                            <span className={"inline-flex border-blue-500 bg-blue-100 text-blue-500 items-center border px-4 py-1 rounded-md text-sm font-medium "}>
                                                                                {person.level.name}
                                                                            </span>
                                                                        </td>
                                                                        <td className="px-6  border-r py-4 whitespace-nowrap">
                                                                            <div className="flex items-center">
                                                                                {
                                                                                    !person.image &&
                                                                                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    person.image &&
                                                                                    <div className="flex-shrink-0 h-10 w-10">
                                                                                        <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                                                                                    </div>
                                                                                }
                                                                                <div className="ml-4">
                                                                                    <div className="text-sm font-medium text-gray-900">{person.name}</div>
                                                                                    <div className="text-sm text-gray-500">{person.email}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            this.state.tab == 2 &&
                                                                            <td className="px-6 border-r py-4 whitespace-nowrap text-sm font-medium">
                                                                                {person.listings ? person.listings : "-"}
                                                                            </td>
                                                                        }
                                                                        <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                            {
                                                                                (!person.updated || (person.updated && person.updated.id == 3)) &&
                                                                                <span className="inline-flex items-center border border-green-500 px-4 py-1 rounded-md text-sm font-medium bg-green-100 text-green-500">
                                                                                    {"Accepted"}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                person.updated && person.updated.id == 1 &&
                                                                                <span className="inline-flex items-center px-4 py-1 border border-purple-500 rounded-md text-sm font-medium bg-purple-100 text-purple-500">
                                                                                    {"New"}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                person.updated && person.updated.id == 2 &&
                                                                                <span className="inline-flex items-center px-4 py-1 border border-red-500 rounded-md text-sm font-medium bg-red-100 text-red-500">
                                                                                    {"Updated"}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                person.updated && person.updated.id == 4 &&
                                                                                <span className="inline-flex whitespace-no-wrap items-center px-4 py-1 border border-blue-500 rounded-md text-sm font-medium bg-blue-100 text-blue-500">
                                                                                    {"Auto-updated"}
                                                                                </span>
                                                                            }
                                                                            {
                                                                                person.updated && person.updated.id == 5 &&
                                                                                <span className="inline-flex whitespace-no-wrap items-center px-4 py-1 border border-blue-500 rounded-md text-sm font-medium bg-blue-100 text-blue-500">
                                                                                    {"Auto-created"}
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                        {
                                                                            this.state.tab == 2 &&
                                                                            <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <div className="flex flex-row items-center">
                                                                                    {
                                                                                        person.enterprise && !person.enterprise.logo &&
                                                                                        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        person.enterprise && person.enterprise.logo &&
                                                                                        <div className="flex-shrink-0 h-10 w-10 overflow-hidden flex justify-center items-center border rounded-full">
                                                                                            <img className="w-full" src={person.enterprise.logo} alt="" />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        person.enterprise &&
                                                                                        <div className="ml-4  whitespace-nowrap">
                                                                                            <div className="text-sm font-medium text-gray-900 capitalize">{person.enterprise.name}</div>
                                                                                            <div className="text-sm w-40 text-gray-500">{person.enterprise.companyName}<span className="text-sm text-indigo-500">{" "}{person.enterprise.version && person.enterprise.version.id == 1 ? "LIVE" : "DEV"}{" •"}</span>{person.enterprise.legacy && person.enterprise.legacy.value === "enabled" ? person.enterprise.parserType && person.enterprise.parserType.value !== "" ? person.enterprise.parserType.value.indexOf("project") !== -1 ? (<span className="text-sm text-pink-500"> LIGHT • PROJECT</span>) : (<span className="text-sm text-pink-500"> LIGHT • UNIT</span>) : (<span className="text-sm text-pink-500"> LIGHT</span>) : (<span className="text-sm text-indigo-500"> LEGACY</span>)}</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.state.tab == 2 &&
                                                                            <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                                {
                                                                                    (!person.access || (person.access && person.access.id == 1)) &&
                                                                                    <span className="inline-flex items-center border border-orange-500 px-4 py-1 rounded-md text-sm font-medium bg-orange-100 text-orange-500">
                                                                                        {"Owner"}
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    person.access && person.access.id == 2 &&
                                                                                    <span className="inline-flex items-center px-4 py-1 border border-purple-500 rounded-md text-sm font-medium bg-purple-100 text-purple-500">
                                                                                        {"Agent"}
                                                                                    </span>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.state.tab == 2 &&
                                                                            <td className="px-6 border-r py-4 whitespace-nowrap">
                                                                                <div className="flex items-center">
                                                                                    <span className="min-h-10 min-w-10 rounded-full overflow-hidden bg-gray-100 flex justify-center items-center">
                                                                                        <PhoneIcon className="w-5 h-5" />
                                                                                    </span>
                                                                                    <div className="ml-4">
                                                                                        <div className="text-sm font-medium text-gray-900 capitalize">{person.phone}</div>
                                                                                        <div className="text-sm text-gray-500">Phone {person.whatsapp && person.whatsapp.id == 1 && <span>and WhatsApp</span>}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.loading_users &&
                                        <div className="w-full h-64 relative bg-white flex justify-center items-start border-t rounded-b-md">
                                            <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </section>
                        }

                        {
                            !this.state.loading_users &&
                            this.state.users.length > 0 &&
                            <div className="rounded-b-md bg-gray-50">
                                <nav
                                    className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.users.length ? this.state.users.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                            <span className="font-medium">{this.state.total}</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                            <div>
                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                    Page limit: {this.state.page_count}
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {
                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        page_count: item,
                                                                                        page: 1
                                                                                    }, () => {
                                                                                        this.functions.getUsers();
                                                                                    })
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                )}
                                                                            >
                                                                                {item}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    select_all: false,
                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                }, () => {
                                                    this.functions.getUsers();
                                                });
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Previous
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                    this.setState({
                                                        select_all: false,
                                                        page: this.state.page + 1
                                                    }, () => {
                                                        this.functions.getUsers();
                                                    });
                                                }
                                            }}
                                            className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                        >
                                            Next
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        }

                        {/*CREATE USER*/}
                        <CreateUser
                            version={this.state.version}
                            enterprise={this.state.enterprise}
                            agentOnly={true}
                            user={this.state.selected}
                            open={this.state.open}
                            toggle={() => {
                                this.setState({
                                    open: !this.state.open
                                })
                            }}
                            onUser={(user) => {
                                this.state.users.push(user);
                                this.setState({
                                    users: this.state.users
                                })
                            }}
                            onUpdate={(user) => {
                                this.state.users = this.state.users.map((item) => {
                                    if (item.id == user.id) {
                                        item = user;
                                    }
                                    return item;
                                })
                            }}
                        />

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default ListAgents;
