import React, { Component, Fragment } from 'react';
import { LockClosedIcon, LockOpenIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import moment from 'moment';
import CreateAudience from '../../components/createAudience';
import Dropdown from '../../components/dropdown';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListAudience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 1, name: "Encrypted", value: 'encrypted', count: 0, encrypt: true },
            users: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            selected: null
        }
    };

    componentDidMount() {
        this.functions.getAudience();
    }

    functions = {
        getAudience: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getAudience();
                this.setState({
                    users: response.data,
                    total: response.meta.total ? response.meta.total : 0,
                    encrypted: response.meta.encrypted ? response.meta.encrypted : 0,
                    normal: response.meta.normal ? response.meta.normal : 0,
                    loading_users: false,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        removeAudience: async () => {
            let selected_user = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                users: this.state.users.map((item) => {
                    if (item.id == selected_user.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeAudience(selected_user.id);
                await this.promisedSetState({
                    users: this.state.users.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    users: this.state.users.map((item) => {
                        if (item.id == selected_user.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        }
    };

    calls = {
        getAudience: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/audience/listAudience?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value;
            return apiRegister.call(options, url);
        },
        removeAudience: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/audience/removeAudience?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading audience ...</div>
                        </div>
                    </div>
                }

                {/*ERROR WARNING*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={typeof this.state.message == "string" ? this.state.message : "Something went wrong"}
                    cancelButtonText={"ok"}
                    noSubmitButton={true}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Audience"}
                    description={"Are you sure you want to delete this Audience? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.name}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputTitle={'Type "Name" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.name) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeAudience();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*CREATE USER*/}
                <CreateAudience
                    encrypt={this.state.tab.encrypt}
                    audience={this.state.selected}
                    open={this.state.open}
                    toggle={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                    onAudience={(audience) => {
                        console.log(audience);
                        this.functions.getAudience();
                    }}
                    onUpdate={(user) => {

                    }}
                />

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-2 mb-4 md:mb-6">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 1, name: "Encrypted", value: 'encrypted', count: this.state.encrypted, encrypt: true },
                                    { id: 2, name: "Not encrypted", value: 'normal', count: this.state.normal, encrypt: false }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getAudience();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-6"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 1, name: "Created", value: 'createdAt' },
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getAudience();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getAudience();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*AUDIENCE*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white border shadow-lg rounded-lg">
                                    <div className="flex p-4 items-center lg:px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                        <div onClick={() => {
                                            this.setState({
                                                open: true,
                                                selected: null
                                            })
                                        }} className="text-sm  font-medium cursor-pointer ml-4 h-10  px-4  rounded-md bg-purple-100 border-purple-500 items-center flex flex-row text-purple-500 hover:text-purple-70">
                                            <PlusCircleIcon className="w-5 h-5 mr-2" />
                                            Create
                                        </div>
                                    </div>
                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.state.tab.name} Audience</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="relative overflow-x-scroll">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Audience</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Default country code</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Rows</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Created</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <tr key={agent.email} className="border-b border-gray-100">
                                                                    <td className="flex items-center px-6 py-4">
                                                                        <div className="flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center bg-purple-100">
                                                                            {
                                                                                agent.encrypted &&
                                                                                <LockClosedIcon className="w-5 h-5 text-purple-500" />
                                                                            }
                                                                            {
                                                                                !agent.encrypted &&
                                                                                <LockOpenIcon className="w-5 h-5 text-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4 whitespace-no-wrap">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.name ? agent.name : "..."}</div>
                                                                            <div className="text-sm text-gray-500">{agent.encrypted ? "encrypted" : 'not encrypted'}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.defaultCountryCode ? agent.defaultCountryCode.name : '-'}</div>
                                                                        <div className="text-sm text-gray-500">Default country code</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.rows ? agent.rows : '-'}</div>
                                                                        <div className="text-sm text-gray-500">Rows</div>
                                                                    </td>
                                                                    <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{moment(agent.createdAt).format("YYYY-MM-DD")}</div>
                                                                        <div className="text-sm text-gray-500">Created</div>
                                                                    </td>
                                                                    <td className="text-right px-6 whitespace-no-wrap">
                                                                        <div
                                                                            onClick={async () => {
                                                                                await this.promisedSetState({
                                                                                    selected: agent
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    open: true
                                                                                });
                                                                            }}
                                                                            className={"cursor-pointer mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                        >
                                                                            Edit
                                                                        </div>
                                                                        <div
                                                                            onClick={async () => {
                                                                                if (!agent.loading) {
                                                                                    await this.promisedSetState({
                                                                                        remove: agent
                                                                                    });
                                                                                }
                                                                            }}
                                                                            className={"cursor-pointer relative overflow-hidden inline-flex items-center px-4 py-2 text-red-500 border-gray-300 text-sm font-medium rounded-md  bg-white hover:bg-red-100"}
                                                                        >
                                                                            Remove
                                                                            {
                                                                                agent.loading &&
                                                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-500 bg-opacity-50">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.users.length ? this.state.users.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getClients();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getClients();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    this.functions.getClients();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListAudience;
